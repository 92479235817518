import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CampaignCreateJobDetailsForm } from 'src/components/campaigns/campaign-create-job-details-form/CampaignCreateJobDetailsForm'
import { SecurePage } from 'src/components/layout/SecurePage'
import { FetchCampaign } from 'src/constants/actionTypes'
import { getSelectedCampaign } from 'src/selectors/campaigns'

export const UpdateCampaignPage = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const campaignData = useSelector(getSelectedCampaign)

  useEffect(() => {
    dispatch(
      FetchCampaign.request({
        id: params.id,
      }),
    )
  }, [params.id])

  return (
    <SecurePage>
      <div className="p-12">
        <Typography className="w-3/4 mb-4" variant="h1">
          Update Campaign
        </Typography>
        {campaignData ? (
          <CampaignCreateJobDetailsForm campaignData={campaignData} campaignId={params.id} />
        ) : null}
      </div>
    </SecurePage>
  )
}
