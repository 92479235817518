import { composeValidators, createValidator, stateValidator } from 'src/utils/validation/core'
import { isRequired } from 'src/utils/validation/validator'
import validator from 'validator'

export const validateForm = stateValidator({
  email: composeValidators([
    createValidator(isRequired, 'Email is required'),
    createValidator(validator.isEmail, 'Email is invalid'),
  ]),
  password: composeValidators([createValidator(isRequired, 'Password is required')]),
})
