import { Typography, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch, useNavigate } from 'react-router-dom'
import { Logout } from 'src/constants/actionTypes'
import { ROUTES } from 'src/constants/routes'
import { getCompany } from 'src/selectors/company'
import { colors } from 'src/theme'
import { CampaignsIcon, LogoutIcon, OverviewIcon, UsersIcon } from '../shared/Icons'

interface Props {
  campaignsExist?: boolean
}

export const SecureLeftMenu = ({ campaignsExist }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const overviewMatch = useMatch(ROUTES.OVERVIEW_URL)
  const campaignsMatch =
    useMatch(`${ROUTES.CAMPAIGNS_URL}/*`) || useMatch(`${ROUTES.APPLICANT_URL}/*`)
  const accountMatch = useMatch(`${ROUTES.ACCOUNT_URL}`)

  const company = useSelector(getCompany)

  const logout = () => {
    dispatch(Logout.request())
  }

  return (
    <div className="w-60 px-4 py-8 h-full" style={{ borderRight: '1px solid #E0E0E0' }}>
      <Typography className="mb-8" fontWeight={700} variant="body1">
        {company?.displayName}
      </Typography>
      <Button
        className="w-full justify-start"
        startIcon={
          <div className="w-8 flex justify-center">
            <OverviewIcon fill={overviewMatch ? colors.BRAND_BLUE : colors.BRAND_NAVY} />
          </div>
        }
        color={overviewMatch ? 'primary' : 'secondary'}
        onClick={() => navigate(ROUTES.OVERVIEW_URL)}
      >
        Overview
      </Button>
      <Button
        className="w-full justify-start"
        startIcon={
          <div className="w-8 flex justify-center">
            <CampaignsIcon stroke={campaignsMatch ? colors.BRAND_BLUE : colors.BRAND_NAVY} />
          </div>
        }
        color={campaignsMatch ? 'primary' : 'secondary'}
        onClick={() =>
          navigate(campaignsExist ? ROUTES.CAMPAIGNS_URL : ROUTES.FIRST_CREATE_CAMPAIGN_URL)
        }
      >
        {campaignsExist ? 'Campaigns' : 'Create Campaign'}
      </Button>
      <Button
        className="w-full justify-start"
        startIcon={
          <div className="w-8 flex justify-center">
            <UsersIcon stroke={accountMatch ? colors.BRAND_BLUE : colors.BRAND_NAVY} />
          </div>
        }
        color={accountMatch ? 'primary' : 'secondary'}
        onClick={() => navigate(ROUTES.ACCOUNT_URL)}
      >
        Account
      </Button>
      <Button
        className="w-full justify-start"
        startIcon={
          <div className="w-8 flex justify-center">
            <LogoutIcon fill={colors.BRAND_NAVY} />
          </div>
        }
        color="secondary"
        onClick={logout}
      >
        Log out
      </Button>
      {/* <MenuItem url={ROUTES.ENTER_ONFLEET_KEY_ONBOARDING_URL} label="Log Out" /> */}
    </div>
  )
}
