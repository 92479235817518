import { Button, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useEffect } from 'react'
import { SecurePage } from 'src/components/layout/SecurePage'
import { useNavigate, useParams } from 'react-router-dom'
import { FetchApplicant } from 'src/constants/actionTypes'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedApplicant } from 'src/selectors/applicants'
import { ApplicantContacts } from 'src/components/applicants/applicant-info/ApplicantContacts'
import { ApplicantPlatformInfo } from 'src/components/applicants/applicant-info/ApplicantPlatformInfo'
import { colors } from 'src/theme'

export const ApplicantPage = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const applicant = useSelector(getSelectedApplicant)

  useEffect(() => {
    if (!params.id) {
      navigate(-1)
      return
    }

    dispatch(FetchApplicant.request({ id: params.id }))
  }, [params.id])

  const handleBack = () => {
    navigate(-1)
  }

  if (!applicant) {
    return null
  }

  const platformData = applicant.driver?.platformData

  return (
    <SecurePage>
      <div className="w-full px-8 py-12">
        <div className="flex justify-between">
          <Button
            startIcon={<ArrowBack className="text-5xl" color="secondary" onClick={handleBack} />}
          >
            <Typography variant="h2">{`${applicant?.driver?.firstName} ${applicant?.driver?.lastName}`}</Typography>
          </Button>
          <Button variant="outlined" color="primary" className="mr-6">
            Import to Onfleet
          </Button>
        </div>

        <div className="ml-16 items-start flex flex-col">
          <ApplicantContacts applicant={applicant} />
          {platformData ? (
            platformData.map((item) => (
              <ApplicantPlatformInfo applicantPlatformData={item as any} />
            ))
          ) : (
            <div
              style={{
                backgroundColor: 'white',
                border: '1px solid #DAD9E0',
                borderRadius: '8px',
                padding: '90px 190px',
              }}
            >
              <Typography style={{ color: colors.GRAY_DARK_COOL }} fontWeight={500}>
                Driver Profile Not Available
              </Typography>
            </div>
          )}
        </div>
      </div>
    </SecurePage>
  )
}
