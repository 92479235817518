import { Button, Typography, TextField, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { FieldArray, useFormikContext } from 'formik'
import { CampaignCreateJobDetailsFormValues } from './CampaignCreateJobDetailsForm.interface'
import styled from '@emotion/styled'

export const CampaignCreateJobDetailsFormContent = () => {
  const { handleChange, handleBlur, values, touched, errors } =
    useFormikContext<CampaignCreateJobDetailsFormValues>()

  return (
    <div>
      <Typography className="mt-4 mb-4" variant="subtitle1">
        Campaign Name
      </Typography>
      <TextField
        variant="outlined"
        className="w-full mb-4"
        onChange={handleChange('name')}
        onBlur={handleBlur('name')}
        value={values.name}
        error={Boolean(touched.name && errors.name)}
        helperText={touched.name && errors.name}
        label="Enter Campaign Name"
        placeholder="Para / San Francisco Campaign"
      />
      <Typography className="mt-4 mb-4" variant="subtitle1">
        Banner Details
      </Typography>
      <TextField
        variant="outlined"
        className="w-full mb-4"
        onChange={handleChange('cardTitle')}
        onBlur={handleBlur('cardTitle')}
        value={values.cardTitle}
        error={Boolean(touched.cardTitle && errors.cardTitle)}
        helperText={touched.cardTitle && errors.cardTitle}
        label="Banner Title"
        inputProps={{ maxLength: 40 }}
        placeholder="Deliver with Para"
      />
      <TextField
        variant="outlined"
        className="w-full mb-4"
        sx={{
          '& .MuiInputBase-root': {
            height: 120,
          },
        }}
        onChange={handleChange('cardContent')}
        onBlur={handleBlur('cardContent')}
        value={values.cardContent}
        error={Boolean(touched.cardContent && errors.cardContent)}
        helperText={touched.cardContent && errors.cardContent}
        multiline
        label="Banner Copy"
        inputProps={{ maxLength: 160 }}
        placeholder="Make up to $25/hr. Need trucking license"
      />
      <Typography className="mt-4 mb-4" variant="subtitle1">
        Job Details
      </Typography>
      <TextField
        variant="outlined"
        className="w-full mb-4"
        onChange={handleChange('pageTitle')}
        onBlur={handleBlur('pageTitle')}
        value={values.pageTitle}
        error={Boolean(touched.pageTitle && errors.pageTitle)}
        helperText={touched.pageTitle && errors.pageTitle}
        label="Job Title"
        placeholder="Para Delivery"
      />
      <TextField
        variant="outlined"
        className="w-full mb-4"
        sx={{
          '& .MuiInputBase-root': {
            height: 120,
          },
        }}
        onChange={handleChange('pageContent')}
        onBlur={handleBlur('pageContent')}
        value={values.pageContent}
        multiline
        error={Boolean(touched.pageContent && errors.pageContent)}
        helperText={touched.pageContent && errors.pageContent}
        label="Job Description"
        inputProps={{ maxLength: 160 }}
        placeholder="Routes available weekly, flexible scheduling"
      />
      <TextField
        variant="outlined"
        className="w-full mb-4"
        onChange={handleChange('price')}
        onBlur={handleBlur('price')}
        value={values.price}
        error={Boolean(touched.price && errors.price)}
        helperText={touched.price && errors.price}
        label="Payout"
        placeholder="$25-30"
        inputProps={{ maxLength: 6 }}
      />
      <TextField
        variant="outlined"
        className="w-full mb-4"
        onChange={handleChange('priceUnit')}
        onBlur={handleBlur('priceUnit')}
        value={values.priceUnit}
        error={Boolean(touched.priceUnit && errors.priceUnit)}
        helperText={touched.priceUnit && errors.priceUnit}
        label="Price per unit"
        placeholder="per delivery"
      />
      <FieldArray
        name="requirements"
        render={(arrayHelpers) => (
          <div>
            {values.requirements.map((requirement, index) => {
              const touchedRequirements = touched.requirements as Array<boolean> | undefined
              const isTouched = touchedRequirements ? touchedRequirements[index] : false
              const error = errors.requirements ? errors.requirements[index] : ''

              return (
                <div key={index} className="relative">
                  <TextField
                    name={`requirements.${index}`}
                    variant="outlined"
                    className="w-full mb-4"
                    onChange={handleChange(`requirements.${index}`)}
                    onBlur={handleBlur(`requirements.${index}`)}
                    value={requirement}
                    error={Boolean(isTouched && error)}
                    helperText={isTouched && error}
                    label="Job Requirement"
                    placeholder="Vehicle insurance required"
                  />
                  <DeleteIconContainer onClick={() => arrayHelpers.remove(index)}>
                    <Delete />
                  </DeleteIconContainer>
                </div>
              )
            })}
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => arrayHelpers.insert(values.requirements.length, '')}
            >
              Add Requirement
            </Button>
          </div>
        )}
      />
    </div>
  )
}

const DeleteIconContainer = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: -12px;
  transform: translate(100%, 0);
`
