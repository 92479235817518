export enum Endpoints {
  LoginEmailPassword = '/v1/paraworks-user/login',
  RegisterEmailPassword = '/v1/paraworks-user/register',
  ForgotPassword = '/v1/paraworks-user/forgot-password',
  RestorePassword = '/v1/paraworks-user/restore-password',

  FetchUser = '/v1/paraworks-user/user',
  UpdatePassword = '/v1/paraworks-user/change-password',

  FetchCompany = '/v1/partners/owner',
  CreateCompany = '/v1/partners',
  UpdateCompany = '/v1/partners/:id',

  FetchCampaigns = '/v1/campaigns',
  FetchCampaign = '/v1/campaigns/:id',
  CreateCampaign = '/v1/campaigns',
  UpdateCampaign = '/v1/campaigns/:id',
  StartCampaign = '/v1/campaigns/:id/start',
  StopCampaign = '/v1/campaigns/:id/stop',
  FetchCampaignsOverview = '/v1/campaigns/overview',
  FetchApplicants = '/v1/campaigns/:id/campaign-conversions',
  FetchApplicant = '/v1/campaign-conversions',
  UpdateApplicant = '/v1/campaign-conversions/:id',
  FetchLocations = '/v1/campaigns/locations',

  FetchOnfleetTeams = '/v1/campaign-conversions/onfleet-teams',
  OnfleetApplicantImport = '/v1/campaign-conversions/onfleet-import',

  ConfirmWorksEmail = '/v1/mobile/drivers/import/confirm-email/unauthorized',
  ConfirmHomeProfileEmail = '/v1/profile/basic-info/confirm-email/unauthorized',
  ConfirmPlatformEmail = '/v1/driver-platform-data/confirm-email/unauthorized',
}
