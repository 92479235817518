import { Button, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { SecurePage } from 'src/components/layout/SecurePage'
import { useNavigate, useParams } from 'react-router-dom'
import {
  FetchApplicants,
  FetchCampaign,
  StartCampaign,
  StopCampaign,
} from 'src/constants/actionTypes'
import { useDispatch, useSelector } from 'react-redux'
import { StopCampaignModal } from 'src/components/applicants/StopCampaignModal'
import { StartCampaignModal } from 'src/components/applicants/StartCampaignModal'
import { CampaignStatus } from 'src/constants/campaigns'
import { ApplicantsTableContainer } from 'src/components/applicants/applicants-table/ApplicantsTableContainer'
import { ROUTES } from 'src/constants/routes'
import { getSelectedCampaign } from 'src/selectors/campaigns'
import { getApplicants } from 'src/selectors/applicants'
import { createAsyncAction } from 'src/utils/reduxUtils'

export const CampaignPage = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const campaign = useSelector(getSelectedCampaign)
  const applicants = useSelector(getApplicants)

  const [showStopCampaignModal, setShowStopCampaignModal] = useState<boolean>(false)
  const [showStartCampaignModal, setShowStartCampaignModal] = useState<boolean>(false)
  const [isModalLoading, setModalLoading] = useState<boolean>(false)
  const [modalError, setModalError] = useState<string | null>(null)

  useEffect(() => {
    if (!params.id) {
      navigate(ROUTES.CAMPAIGNS_URL)
      return
    }

    dispatch(FetchCampaign.request({ id: params.id }))
    dispatch(FetchApplicants.request({ id: params.id, limit: 15, offset: 0 }))
  }, [params.id])

  const stopCampaign = async () => {
    setModalLoading(true)

    try {
      await createAsyncAction(
        dispatch,
        StopCampaign.request({
          id: params.id,
        }),
      )

      handleCloseStopCampaignModal()

      navigate(ROUTES.CAMPAIGNS_URL)
    } catch (err: any) {
      setModalError(err)
    } finally {
      setModalLoading(false)
    }
  }
  const startCampaign = async () => {
    setModalLoading(true)

    try {
      await createAsyncAction(
        dispatch,
        StartCampaign.request({
          id: params.id,
        }),
      )

      handleCloseStartCampaignModal()

      navigate(ROUTES.CAMPAIGNS_URL)
    } catch (err: any) {
      setModalError(err)
    } finally {
      setModalLoading(false)
    }
  }

  const handleApplicantsPaginationChange = (limit: number, offset: number, search: string) => {
    dispatch(
      FetchApplicants.request({
        id: params.id,
        limit,
        offset,
        search,
      }),
    )
  }

  const handleShowStopCampaignModal = () => {
    setShowStopCampaignModal(true)
  }

  const handleCloseStopCampaignModal = () => {
    setModalError(null)
    setShowStopCampaignModal(false)
  }

  const handleShowStartCampaignModal = () => {
    setShowStartCampaignModal(true)
  }

  const handleCloseStartCampaignModal = () => {
    setModalError(null)
    setShowStartCampaignModal(false)
  }

  const handleUpdateCampaign = () => {
    if (!params.id) {
      return
    }

    navigate(ROUTES.UPDATE_CAMPAIGN_URL.replace(':id', params.id))
  }

  const handleBack = () => {
    navigate(ROUTES.CAMPAIGNS_URL)
  }

  if (!campaign) {
    return null
  }

  return (
    <SecurePage>
      <div className="w-full px-8 py-12">
        <div className="flex justify-between">
          <Button
            startIcon={<ArrowBack className="text-5xl" color="secondary" onClick={handleBack} />}
          >
            <Typography variant="h2">{campaign.name}</Typography>
          </Button>
          <div>
            {campaign.status === CampaignStatus.Active ? (
              <Button
                variant="outlined"
                color="secondary"
                className="mr-6"
                onClick={handleShowStopCampaignModal}
              >
                Stop Campaign
              </Button>
            ) : null}
            {campaign.status === CampaignStatus.Approved ||
            campaign.status === CampaignStatus.Inactive ? (
              <Button
                variant="contained"
                color="primary"
                className="mr-6"
                onClick={handleShowStartCampaignModal}
              >
                Start Campaign
              </Button>
            ) : null}
            <Button variant="outlined" color="secondary" onClick={handleUpdateCampaign}>
              Update Campaign
            </Button>
          </div>
        </div>

        <ApplicantsTableContainer
          applicants={applicants}
          limit={15}
          onPaginationChange={handleApplicantsPaginationChange}
        />
      </div>
      <StopCampaignModal
        isOpen={showStopCampaignModal}
        isLoading={isModalLoading}
        error={modalError}
        onSubmit={stopCampaign}
        onClose={handleCloseStopCampaignModal}
      />
      <StartCampaignModal
        isOpen={showStartCampaignModal}
        isLoading={isModalLoading}
        error={modalError}
        onSubmit={startCampaign}
        onClose={handleCloseStartCampaignModal}
      />
    </SecurePage>
  )
}
