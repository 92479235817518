import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { FetchOnfleetTeams, ImportApplicantsToOnfleet } from 'src/constants/actionTypes'
import { ApiService, callSecureApi } from './api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { OnfleetTeam } from 'src/interfaces/onfleet'

function* onFetchOnfleetTeams(action: AsyncAction<void>) {
  try {
    const { payload }: { payload: Array<OnfleetTeam> } = yield call(
      callSecureApi,
      Endpoints.FetchOnfleetTeams,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(FetchOnfleetTeams.success(payload))

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(FetchOnfleetTeams.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface ImportApplicantsToOnfleetRequest {
  teamList: Array<string>
  idList: Array<string>
}

function* onImportApplicantsToOnfleet(action: AsyncAction<ImportApplicantsToOnfleetRequest>) {
  const { teamList, idList } = action.payload

  try {
    yield call(callSecureApi, Endpoints.OnfleetApplicantImport, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: {
        teamList,
        idList,
      },
    })

    yield put(ImportApplicantsToOnfleet.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(ImportApplicantsToOnfleet.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* onfleetSaga() {
  yield takeEvery(FetchOnfleetTeams.type.REQUEST, onFetchOnfleetTeams)
  yield takeEvery(ImportApplicantsToOnfleet.type.REQUEST, onImportApplicantsToOnfleet)
}
