import { composeValidators, createValidator, stateValidator } from 'src/utils/validation/core'
import { isRequired, isPasswordValid } from 'src/utils/validation/validator'

const isConfirmPasswordValid = (value: string, context: { newPassword: string }) =>
  value === context.newPassword

export const validateForm = stateValidator({
  oldPassword: composeValidators([createValidator(isRequired, 'Required')]),

  newPassword: composeValidators([
    createValidator(isRequired, 'Password is required'),
    createValidator(isPasswordValid, 'Password is not valid. Must be 8 or more characters'),
  ]),
  confirmNewPassword: composeValidators([
    createValidator(isRequired, 'Password confirmation is required'),
    createValidator(isConfirmPasswordValid, 'Passwords should match'),
  ]),
})
