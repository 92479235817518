import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { AppRoutes } from './AppRoutes'
import { configureStore } from './utils/configureStore'
import { Provider } from 'react-redux'
import { HooksContainer } from './HooksContainer'
import { memo } from 'react'

const store = configureStore()

export const App = memo(() => {
  return (
    <Provider store={store}>
      <HooksContainer />
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <AppRoutes />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  )
})
