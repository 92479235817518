import styled from '@emotion/styled'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export const AuthFormContainer = ({ children }: Props) => {
  return (
    <Root className="pt-12 pb-12">
      <Container>{children}</Container>
    </Root>
  )
}

const Root = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  width: 330px;
  margin: 0 auto;
`
