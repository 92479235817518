import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { LoginEmailPassword } from 'src/constants/actionTypes'
import { ROUTES } from 'src/constants/routes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { LoginFormValues } from './LoginForm.interface'
import { validateForm } from './LoginForm.validator'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'

const initialValues: LoginFormValues = {
  email: '',
  password: '',
}

export const LoginForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const formValidator = (values: LoginFormValues) => validateForm(values)

  const handleFormSubmit = async (values: LoginFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(dispatch, LoginEmailPassword.request(values))
    } catch (err: any) {
      const errorMessage =
        typeof err === 'string' ? err : err?.message ? err?.message : 'Failed to login'

      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const handleRegisterNavigate = () => {
    navigate(ROUTES.REGISTER_URL)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormSubmit} validate={formValidator}>
      {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
        <div>
          <TextField
            variant="outlined"
            className="w-full mb-4"
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            label="Email Address"
          />
          <TextField
            variant="outlined"
            className="w-full mb-4"
            onChange={handleChange('password')}
            onBlur={handleBlur('password')}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            type="password"
            label="Password"
          />
          <div className="text-right mb-4">
            <Link to={ROUTES.FORGOT_PASSWORD_URL}>
              <Typography variant="body1">Forgot Password?</Typography>
            </Link>
          </div>
          {error ? (
            <div className="mb-4 text-center">
              <Typography variant="body1" color="error.main">
                {error}
              </Typography>
            </div>
          ) : null}
          <LoadingButton
            className="w-full mt-2"
            variant="contained"
            size="large"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            Log In
          </LoadingButton>
          <Button
            className="w-full mt-4"
            size="large"
            variant="outlined"
            onClick={handleRegisterNavigate}
          >
            New Here? Sign Up
          </Button>
        </div>
      )}
    </Formik>
  )
}
