import React, { useEffect } from 'react'
import { Box, Modal, Button, Typography } from '@mui/material'
import { CheckCircle, Brightness1Outlined } from '@mui/icons-material'

interface Props {
  onClose: () => void
  open?: boolean
}

export const STORAGE_FIRST_CAMPAIGN_CREATED_SHOWN_KEY = 'firstCampaignCreatedShown'

const FirstCampaignCreatedModalComponent = ({ onClose, open }: Props) => {
  useEffect(() => {
    localStorage.setItem(STORAGE_FIRST_CAMPAIGN_CREATED_SHOWN_KEY, 'yes')
  })
  return (
    <Modal
      open={!!open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '780px',
          bgcolor: 'background.paper',
          p: '50px 150px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h2">You’ve created your first campaign.</Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
          Before it is launched our team will review all of your campaign details for approval. We
          will reach out via email after review with next steps.{' '}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircle style={{ marginRight: 12 }} />
          <Typography variant="body1">Create your campaign</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Brightness1Outlined style={{ marginRight: 12 }} />
          <Typography variant="body1">ParaPartner campaign review</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '48px' }}>
          <Brightness1Outlined style={{ marginRight: 12 }} />
          <Typography variant="body1">Campaign launch</Typography>
        </div>
        <Box>
          <Button sx={{ width: '230px' }} color="primary" variant="contained" onClick={onClose}>
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export const FirstCampaignCreatedModal = React.memo(FirstCampaignCreatedModalComponent)
