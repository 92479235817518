import React from 'react'
import {
  Table,
  Box,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  Checkbox,
  TablePagination,
  TableRow,
  Paper,
  Typography,
} from '@mui/material'
import { Applicant } from 'src/interfaces/applicants'
import { colors } from 'src/theme'
import { ApplicantsTableHeader } from './ApplicantsTableHeader'
import moment from 'moment'
import { ContactStatusSelect } from './ContactStatusSelect'

interface Props {
  applicants: Applicant[]
  page: number
  rowsPerPage: number
  total: number
  selected: string[]
  setPage(page: number): void
  setRowsPerPage(rowsPerPage: number): void

  onClick: (applicantId: string) => void
  onCheck: (applicantId: string, forceSelect?: boolean) => void
  onImportToOnfleet(): void
}

export const ApplicantsTable = ({
  applicants,
  page,
  rowsPerPage,
  total,
  selected,
  setPage,
  setRowsPerPage,
  onClick,
  onCheck,
  onImportToOnfleet,
}: Props) => {
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1

  const handleImport = (row: Applicant) => {
    onCheck(row.id, true)
    onImportToOnfleet()
  }

  return (
    <Box sx={{ width: '100%', marginTop: 4 }}>
      <Paper sx={{ width: '100%', mb: 2, borderRadius: '8px', overflow: 'hidden' }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <ApplicantsTableHeader />
            <TableBody>
              {applicants.map((row) => {
                const labelId = `enhanced-table-checkbox-${row.id}`
                const isItemSelected = isSelected(row.id)

                return (
                  <TableRow hover onClick={() => onClick(row.id)} tabIndex={-1} key={row.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        disabled={row.onfleetImported}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                        onClick={(event) => {
                          event.stopPropagation()

                          if (row.onfleetImported) {
                            return
                          }

                          onCheck(row.id)
                        }}
                      />
                    </TableCell>
                    <TableCell id={labelId} align="left">
                      <Typography>{`${row?.driver?.firstName} ${row?.driver?.lastName}`}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>{row?.driver?.phone}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>{row?.driver?.email}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>{moment(row.createdAt * 1000).format('DD/MM/YYYY')}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <div className="flex items-start">
                        <ContactStatusSelect
                          applicantId={row.id}
                          contactStatus={row.contactStatus}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      {row.onfleetImported ? (
                        <Button
                          style={{
                            borderColor: colors.BRAND_BLUE,
                            backgroundColor: colors.BRAND_BLUE,
                            height: '40px',
                            borderRadius: '4px',
                          }}
                          disabled
                          variant="contained"
                        >
                          <Typography color="white" variant="body2" fontWeight={500}>
                            Imported to Onfleet
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          style={{
                            borderColor: colors.BRAND_BLUE,
                            backgroundColor: 'transparent',
                            height: '40px',
                            borderRadius: '4px',
                          }}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation()
                            handleImport(row)
                          }}
                        >
                          <Typography color={colors.BRAND_BLUE} variant="body2" fontWeight={500}>
                            Import to Onfleet
                          </Typography>
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
