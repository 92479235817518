import { Routes, Route, Navigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { ForgotPasswordConfirmPage } from 'src/pages/auth/ForgotPasswordConfirmPage'
import { ForgotPasswordPage } from 'src/pages/auth/ForgotPasswordPage'
import { LoginPage } from 'src/pages/auth/LoginPage'
import { RegisterPage } from 'src/pages/auth/RegisterPage'
import { ConfirmHomeProfileEmailPage } from 'src/pages/dynamic-links/ConfirmHomeProfileEmail'
import { ConfirmPlatformEmailPage } from 'src/pages/dynamic-links/ConfirmPlatformEmail'
import { ConfirmWorksEmailPage } from 'src/pages/dynamic-links/ConfirmWorksEmail'

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.LOGIN_URL} element={<LoginPage />} />
      <Route path={ROUTES.REGISTER_URL} element={<RegisterPage />} />
      <Route path={ROUTES.FORGOT_PASSWORD_URL} element={<ForgotPasswordPage />} />
      <Route path={ROUTES.FORGOT_PASSWORD_CONFIRM_URL} element={<ForgotPasswordConfirmPage />} />
      <Route path={ROUTES.CONFIRM_WORKS_EMAIL} element={<ConfirmWorksEmailPage />} />
      <Route path={ROUTES.CONFIRM_HOME_PROFILE_EMAIL} element={<ConfirmHomeProfileEmailPage />} />
      <Route path={ROUTES.CONFIRM_PLATFORM_EMAIL} element={<ConfirmPlatformEmailPage />} />
      <Route path="*" element={<Navigate to={ROUTES.LOGIN_URL} />} />
    </Routes>
  )
}
