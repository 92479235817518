import React, { useRef } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { Box, Modal, Button } from '@mui/material'

interface Props {
  image?: string
  onClose: () => void
  onCrop: (image: string) => void
  aspectRatio?: number
}

const ImageCropperComponent: React.FC<Props> = ({ aspectRatio = 1, image, onCrop, onClose }) => {
  const imageRef = useRef(null)
  const [cropper, setCropper] = React.useState<Cropper>()

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      onCrop(cropper.getCroppedCanvas().toDataURL())

      onClose()
    }
  }

  return (
    <Modal
      open={!!image}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '800px',
          bgcolor: 'background.paper',
          p: 4,
          borderRadius: '16px',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '600px',
        }}
      >
        <Cropper
          style={{ width: '100%', height: '440px' }}
          preview=".img-preview"
          aspectRatio={aspectRatio}
          guides={true}
          src={image}
          ref={imageRef}
          dragMode={'move'}
          onInitialized={(instance) => {
            setCropper(instance)
          }}
        />
        <Button
          color="primary"
          variant="contained"
          sx={{ marginTop: '16px', width: '30%' }}
          onClick={getCropData}
        >
          Save
        </Button>
      </Box>
    </Modal>
  )
}

export const ImageCropper = React.memo(ImageCropperComponent)
