import { FetchCompany, Logout } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { CompanyState } from './types'

const getInitialState = (): CompanyState => ({
  company: null,
  isCompanyInitialized: false,
})

export const companyReducer = (
  state: CompanyState = getInitialState(),
  action: Action<any>,
): CompanyState => {
  switch (action.type) {
    case FetchCompany.type.SUCCESS:
      return {
        ...state,
        isCompanyInitialized: true,
        company: action.payload,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
