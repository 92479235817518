import React from 'react'
import { Box, Modal, Button, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

interface Props {
  isOpen: boolean
  isLoading?: boolean
  error: string | null
  onSubmit(): void
  onClose(): void
}

const StartCampaignModalComponent = ({ isOpen, isLoading, error, onSubmit, onClose }: Props) => {
  return (
    <Modal
      container={() => document.getElementById('root')}
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '780px',
          bgcolor: 'background.paper',
          p: '50px 150px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h2">You may only have one launched campaign at a time.</Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
          Starting this campaign will stop your previously launched campaign.Are you sure you wish
          to continue?{' '}
        </Typography>
        {error ? (
          <div className="mb-4 text-center">
            <Typography variant="body1" color="error.main">
              {error}
            </Typography>
          </div>
        ) : null}
        <Box>
          <Button
            sx={{ mr: 2, width: '230px' }}
            color="secondary"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{ width: '230px' }}
            loading={isLoading}
            color="primary"
            variant="contained"
            onClick={onSubmit}
          >
            Launch Campaign
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  )
}

export const StartCampaignModal = React.memo(StartCampaignModalComponent)
