import { Logout, SetUser, SetUserToken } from 'src/constants/actionTypes'
import { UserService } from 'src/services/UserService'
import { Action } from 'src/utils/reduxUtils'
import { AuthState } from './types'

const getInitialState = (): AuthState => ({
  user: UserService.getStoredUser(),
  userToken: UserService.getStoredUserToken(),
  isUserInitialized: false,
})

export const authReducer = (
  state: AuthState = getInitialState(),
  action: Action<any>,
): AuthState => {
  switch (action.type) {
    case SetUser.type.SUCCESS:
      return {
        ...state,
        isUserInitialized: true,
        user: action.payload,
      }
    case SetUserToken.type.SUCCESS:
      return {
        ...state,
        userToken: action.payload,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
