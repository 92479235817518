import { SetCampaignCreateData, DeleteCampaignCreateData, Logout } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { CampaignCreateState } from './types'

const getInitialState = (): CampaignCreateState => ({
  locationRecordId: '',
  cardTitle: undefined,
  cardContent: undefined,
  pageTitle: undefined,
  pageContent: undefined,
  price: undefined,
  priceUnit: undefined,
  requirements: [],
})

export const campaignCreateReducer = (
  state: CampaignCreateState = getInitialState(),
  action: Action<any>,
): CampaignCreateState => {
  switch (action.type) {
    case SetCampaignCreateData.type.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case DeleteCampaignCreateData.type.SUCCESS:
      return getInitialState()
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
