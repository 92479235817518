import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import { colors } from 'src/theme'

interface Props {
  companyName?: string
  title?: string
  price?: string
  description?: string
  priceUnit?: string
  requirements: Array<string>
}

export const JobPreview = ({
  companyName,
  title,
  price,
  priceUnit,
  description,
  requirements,
}: Props) => {
  return (
    <div className="relative">
      <img
        style={{ width: 246, height: 512 }}
        src={require('../../../assets/images/jobPreview.png')}
      />
      <Container>
        <Card>
          <div className="flex justify-between mb-2">
            <Title className="mb-1" fontWeight={700} fontSize={14} lineHeight={'14px'}>
              {title || 'Job Title'}
            </Title>
            <div className="ml-2">
              <Typography className="text-right" fontWeight={700} fontSize={13} lineHeight={'14px'}>
                {price || 'Payout'}
              </Typography>
              <Typography
                className="text-right"
                fontSize={10}
                lineHeight={'10px'}
                color={colors.GRAY_COOL_GRAY}
              >
                {priceUnit || 'Price Unit'}
              </Typography>
            </div>
          </div>
          <Typography fontSize={10} lineHeight={'12px'} fontWeight={600}>
            {description || 'Job Description'}
          </Typography>
        </Card>
        <Card>
          <Typography className="mb-2" fontSize={14} fontWeight={700}>
            How does this work
          </Typography>
          <Typography className="mb-2" fontSize={10} lineHeight="12px" fontWeight={700}>
            1. Click the "Sign Up" button below
          </Typography>
          <Typography className="mb-2" fontSize={10} lineHeight="12px" fontWeight={700}>
            2. Complete sign up with {companyName}
          </Typography>
          <Typography fontSize={10} lineHeight="12px" fontWeight={700}>
            3. {companyName} will reach out to you about next steps and training
          </Typography>
        </Card>
        {requirements.length ? (
          <Card>
            <Typography className="mb-2" fontSize={14} fontWeight={700}>
              Requirements
            </Typography>
            {requirements.map((requirement, idx) => (
              <Typography
                key={idx}
                className="mb-1"
                fontSize={10}
                lineHeight="12px"
                color={colors.GRAY_COOL_GRAY}
              >
                {requirement}
              </Typography>
            ))}
          </Card>
        ) : null}
      </Container>
    </div>
  )
}

const Container = styled.div`
  width: 220px;
  height: 340px;
  position: absolute;
  top: 70px;
  left: 13px;
  padding: 10px 6px;
  background: ${colors.GRAY_PALE_COOL};
  overflow: scroll;
`

const Card = styled.div`
  position: relative;
  padding: 8px 6px 10px;
  border-radius: 4px;
  margin-bottom: 6px;
  background: ${colors.GRAY_WHITE};
  box-shadow: 0 1px 2px ${colors.GRAY_MID_COOL};
`

const Title = styled(Typography)`
  height: 14px;
  overflow: hidden;
`
