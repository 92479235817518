import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  ConfirmHomeProfileEmail,
  ConfirmPlatformEmail,
  ConfirmWorksEmail,
} from 'src/constants/actionTypes'
import { ApiService, callApi } from './api/callApi'
import { Endpoints } from 'src/constants/endpoints'

interface ConfirmWorksEmailRequest {
  code: string
  userId: string
}

function* onConfirmWorksEmail(action: AsyncAction<ConfirmWorksEmailRequest>) {
  const { code, userId } = action.payload

  try {
    yield call(callApi, Endpoints.ConfirmWorksEmail, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: {
        code,
        userId,
      },
    })

    yield put(ConfirmWorksEmail.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to confirm'

    yield put(ConfirmWorksEmail.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface ConfirmHomeProfileEmailRequest {
  code: string
  email: string
  userId: string
}

function* onConfirmHomeProfileEmail(action: AsyncAction<ConfirmHomeProfileEmailRequest>) {
  const { code, email, userId } = action.payload

  try {
    yield call(callApi, Endpoints.ConfirmHomeProfileEmail, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: {
        code,
        email,
        userId,
      },
    })

    yield put(ConfirmHomeProfileEmail.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to confirm'

    yield put(ConfirmHomeProfileEmail.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface ConfirmPlatformEmailRequest {
  code: string
  platform: string
  userId: string
}

function* onConfirmPlatformEmail(action: AsyncAction<ConfirmPlatformEmailRequest>) {
  const { code, platform, userId } = action.payload

  try {
    yield call(callApi, Endpoints.ConfirmPlatformEmail, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: {
        code,
        platform,
        userId,
      },
    })

    yield put(ConfirmPlatformEmail.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to confirm'

    yield put(ConfirmPlatformEmail.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* dynamicLinksSaga() {
  yield takeEvery(ConfirmWorksEmail.type.REQUEST, onConfirmWorksEmail)
  yield takeEvery(ConfirmHomeProfileEmail.type.REQUEST, onConfirmHomeProfileEmail)
  yield takeEvery(ConfirmPlatformEmail.type.REQUEST, onConfirmPlatformEmail)
}
