import { createActions } from 'src/utils/reduxUtils'

export const LoginEmailPassword = createActions('LoginEmailPassword')

export const RegisterEmailPassword = createActions('RegisterEmailPassword')

export const ForgotPassword = createActions('ForgotPassword')

export const RestorePassword = createActions('RestorePassword')

export const FetchUser = createActions('FetchUser')

export const UpdatePassword = createActions('UpdatePassword')

export const SetUser = createActions('SetUser')

export const SetUserToken = createActions('SetUserToken')

export const Logout = createActions('Logout')

export const SetOnboardingData = createActions('SetOnboardingData')

export const FetchCompany = createActions('FetchCompany')

export const CreateCompany = createActions('CreateCompany')

export const UpdateCompany = createActions('UpdateCompany')

export const FetchCampaigns = createActions('FetchCampaigns')

export const FetchCampaign = createActions('FetchCampaign')

export const CreateCampaign = createActions('CreateCampaign')

export const UpdateCampaign = createActions('UpdateCampaign')

export const FetchLocations = createActions('FetchLocations')

export const SetCampaignCreateData = createActions('SetCampaignCreateData')

export const DeleteCampaignCreateData = createActions('DeleteCampaignCreateData')

export const FetchApplicants = createActions('FetchApplicants')

export const FetchApplicant = createActions('FetchApplicant')

export const UpdateApplicant = createActions('UpdateApplicant')

export const FetchCampaignsOverview = createActions('FetchCampaignsOverview')

export const StartCampaign = createActions('StartCampaign')

export const StopCampaign = createActions('StopCampaign')

export const FetchOnfleetTeams = createActions('FetchOnfleetTeams')

export const ImportApplicantsToOnfleet = createActions('ImportApplicantsToOnfleet')

export const ConfirmWorksEmail = createActions('ConfirmWorksEmail')

export const ConfirmHomeProfileEmail = createActions('ConfirmHomeProfileEmail')

export const ConfirmPlatformEmail = createActions('ConfirmPlatformEmail')
