import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { RestorePassword } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { ForgotPasswordConfirmFormValues } from './ForgotPasswordConfirmForm.interface'
import { validateForm } from './ForgotPasswordConfirmForm.validator'
import LoadingButton from '@mui/lab/LoadingButton'
import { useSearchParams } from 'react-router-dom'

const initialValues: ForgotPasswordConfirmFormValues = {
  password: '',
  confirmPassword: '',
}

export const ForgotPasswordConfirmForm = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const formValidator = (values: ForgotPasswordConfirmFormValues) => validateForm(values, values)

  const handleFormSubmit = async (values: ForgotPasswordConfirmFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(
        dispatch,
        RestorePassword.request({
          password: values.password,
          token: searchParams.get('forgotPasswordToken'),
        }),
      )
    } catch (err: any) {
      const errorMessage =
        typeof err === 'string' ? err : err?.message ? err?.message : 'Failed to login'

      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormSubmit} validate={formValidator}>
      {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
        <div>
          <TextField
            variant="outlined"
            className="w-full mb-4"
            onChange={handleChange('password')}
            onBlur={handleBlur('password')}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            type="password"
            label="Create New Password"
          />
          <TextField
            variant="outlined"
            className="w-full mb-4"
            onChange={handleChange('confirmPassword')}
            onBlur={handleBlur('confirmPassword')}
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            type="password"
            label="Confirm New Password"
          />
          {error ? (
            <div className="mb-4 text-center">
              <Typography variant="subtitle1" color="error.main">
                {error}
              </Typography>
            </div>
          ) : null}
          <LoadingButton
            className="w-full mt-2"
            variant="contained"
            size="large"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            Submit
          </LoadingButton>
        </div>
      )}
    </Formik>
  )
}
