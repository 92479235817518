import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'

interface Props {
  onClose(): void
}

const NoOnfleetKeyModalContentComponent = ({ onClose }: Props) => {
  const navigate = useNavigate()

  return (
    <>
      <Typography variant="h2">Onfleet API key is needed for import</Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        Please go to account settings and add the key{' '}
      </Typography>

      <Box>
        <Button
          sx={{ mr: 2, width: '230px' }}
          color="secondary"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          sx={{ width: '230px' }}
          color="primary"
          variant="contained"
          onClick={() => {
            navigate(ROUTES.ACCOUNT_URL)
          }}
        >
          Go to Account Settings
        </Button>
      </Box>
    </>
  )
}

export const NoOnfleetKeyModalContent = React.memo(NoOnfleetKeyModalContentComponent)
