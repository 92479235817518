import TextField from '@mui/material/TextField'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { SetOnboardingData } from 'src/constants/actionTypes'
import { EnterOnfleetFormValues } from './EnterOnfleetForm.interface'
import { validateForm } from './EnterOnfleetForm.validator'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { ContinueButton } from 'src/components/shared/ContinueButton'
import { Box } from '@mui/material'
import { BackButton } from 'src/components/shared/BackButton'
import { SkipButton } from 'src/components/shared/SkipButton'
import { getOnboardingData } from 'src/selectors/onboarding'
import { getCompany } from 'src/selectors/company'
import { useEffect } from 'react'

export const EnterOnfleetForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onboardingData = useSelector(getOnboardingData)
  const company = useSelector(getCompany)

  const formValidator = (values: EnterOnfleetFormValues) => validateForm(values, values)

  const handleFormSubmit = async (values: EnterOnfleetFormValues) => {
    dispatch(SetOnboardingData.success(values))

    navigate(ROUTES.UPLOAD_LOGO_ONBOARDING_URL)
  }

  useEffect(() => {
    if (company) {
      navigate(ROUTES.OVERVIEW_URL)
    }
  }, [company])

  return (
    <Formik
      initialValues={{ ...onboardingData }}
      onSubmit={handleFormSubmit}
      validate={formValidator}
    >
      {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
        <div className="mt-8">
          <TextField
            variant="outlined"
            className="w-1/2 mb-4"
            onChange={handleChange('onfleetApiKey')}
            onBlur={handleBlur('onfleetApiKey')}
            value={values.onfleetApiKey}
            error={Boolean(touched.onfleetApiKey && errors.onfleetApiKey)}
            helperText={touched.onfleetApiKey && errors.onfleetApiKey}
            label="Onfleet API Key"
          />
          <Box className="mt-6" display="flex">
            <BackButton />
            <ContinueButton
              disabled={!values.onfleetApiKey}
              onClick={() => {
                handleSubmit()
              }}
            />
            <SkipButton
              onClick={() => {
                navigate(ROUTES.UPLOAD_LOGO_ONBOARDING_URL)
              }}
            />
          </Box>
        </div>
      )}
    </Formik>
  )
}
