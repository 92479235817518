import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Typography } from '@mui/material'

interface CampaignsTableHeaderCell {
  id: string
  label: string
}

const headCells: readonly CampaignsTableHeaderCell[] = [
  {
    id: 'cardTitle',
    label: 'Campaign Name',
  },
  {
    id: 'applicants',
    label: 'Applicants',
  },
  {
    id: 'contacted',
    label: 'Contacted',
  },
  {
    id: 'locationRecordId',
    label: 'Location',
  },
  {
    id: 'status',
    label: 'Status',
  },
]

export const CampaignsTableHeader = () => {
  return (
    <TableHead style={{ backgroundColor: 'black' }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" padding="normal">
            <Typography color="white" fontWeight={600}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
