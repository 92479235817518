export enum ApplicantContactStatus {
  Contacted = 'contacted',
  NotContacted = 'notContacted',
}

export enum ApplicantDriverPlatformDataOnTimeCategory {
  GOOD = 'GOOD',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  VERY_LOW = 'VERY_LOW',
  PERFECT = 'PERFECT',
  NO_DATA = 'NO_DATA',
  NOT_ENOUGH_DATA = 'NOT_ENOUGH_DATA',
  NEW_USER = 'NEW_USER',
}

export enum ApplicantDriverPlatformDataType {
  Doordash = 'doordash',
}

export const ApplicantDriverPlatformDataOnTimeCategoryDictionary = {
  [ApplicantDriverPlatformDataOnTimeCategory.GOOD]: 'Good',
  [ApplicantDriverPlatformDataOnTimeCategory.LOW]: 'Low',
  [ApplicantDriverPlatformDataOnTimeCategory.MEDIUM]: 'Medium',
  [ApplicantDriverPlatformDataOnTimeCategory.VERY_LOW]: 'Very low',
  [ApplicantDriverPlatformDataOnTimeCategory.PERFECT]: 'Perfect',
  [ApplicantDriverPlatformDataOnTimeCategory.NO_DATA]: 'No Data',
  [ApplicantDriverPlatformDataOnTimeCategory.NOT_ENOUGH_DATA]: 'Not Enough Data',
  [ApplicantDriverPlatformDataOnTimeCategory.NEW_USER]: 'New User',
}

export const ApplicantContactStatusDictionary = {
  [ApplicantContactStatus.Contacted]: 'Contacted',
  [ApplicantContactStatus.NotContacted]: 'Not Contacted',
}

export const CONTACT_STATUS_SELECT_LIST = [
  {
    value: ApplicantContactStatus.Contacted,
    label: ApplicantContactStatusDictionary[ApplicantContactStatus.Contacted],
  },
  {
    value: ApplicantContactStatus.NotContacted,
    label: ApplicantContactStatusDictionary[ApplicantContactStatus.NotContacted],
  },
]
