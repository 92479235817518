import { ReactNode } from 'react'
import { PublicFooter } from './PublicFooter'
import { PublicHeader } from './PublicHeader'

interface Props {
  children: ReactNode
}

export const PublicPage = ({ children }: Props) => {
  return (
    <div className="h-full flex flex-col">
      <div className="flex-1">
        <PublicHeader />
        {children}
      </div>
      <PublicFooter />
    </div>
  )
}
