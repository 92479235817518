import Typography from '@mui/material/Typography'
import { LoginForm } from 'src/components/auth/login-form/LoginForm'
import { AuthWelcomeContainer } from 'src/components/layout/AuthWelcomeContainer'
import { PublicPage } from 'src/components/layout/PublicPage'
import { colors } from 'src/theme'

export const LoginPage = () => {
  return (
    <PublicPage>
      <AuthWelcomeContainer>
        <Typography variant="h3" fontWeight={600} color={colors.BRAND_BLUE}>
          Welcome Back
        </Typography>
        <Typography className="mb-6 mt-2" variant="subtitle1">
          Log into your account.
        </Typography>
        <LoginForm />
      </AuthWelcomeContainer>
    </PublicPage>
  )
}
