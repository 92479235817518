import { Applicant } from 'src/interfaces/applicants'
import { CallOutlined, MailOutlineOutlined, LocationOnOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { BasicSelect } from 'src/components/shared/form/BasicSelect'
import { CONTACT_STATUS_SELECT_LIST } from 'src/constants/applicants'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { UpdateApplicant } from 'src/constants/actionTypes'

interface Props {
  applicant: Applicant
}

export const ApplicantContacts = ({ applicant }: Props) => {
  const dispatch = useDispatch()
  const [contactStatus, setContactStatus] = useState(applicant?.contactStatus)

  const handleChangeStatus = (contactStatus: any) => {
    dispatch(UpdateApplicant.request({ id: applicant.id, contactStatus }))
  }

  return (
    <div>
      <div className="flex my-6 items-center">
        <MailOutlineOutlined style={{ color: colors.GRAY_DARK_COOL }} />
        <Typography style={{ color: colors.GRAY_DARK_COOL }} className="mr-6 ml-2">
          {applicant?.driver?.email}
        </Typography>
        <CallOutlined style={{ color: colors.GRAY_DARK_COOL }} />
        <Typography style={{ color: colors.GRAY_DARK_COOL }} className="mr-6 ml-2">
          {applicant?.driver?.phone}
        </Typography>
        <LocationOnOutlined style={{ color: colors.GRAY_DARK_COOL }} />
        <Typography style={{ color: colors.GRAY_DARK_COOL }} className="mr-6 ml-2">
          {applicant?.driver?.city}
        </Typography>
      </div>
      <BasicSelect
        className="w-2/3 mb-6"
        onChange={(e) => {
          setContactStatus(e.target.value)

          handleChangeStatus(e.target.value)
        }}
        value={contactStatus}
        options={CONTACT_STATUS_SELECT_LIST}
      />
    </div>
  )
}
