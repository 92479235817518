import React from 'react'
import {
  Box,
  Typography,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Checkbox,
} from '@mui/material'
import { OnfleetTeam } from 'src/interfaces/onfleet'
import { LoadingButton } from '@mui/lab'

interface Props {
  selectedTeams: Array<string>
  teamList: Array<OnfleetTeam>
  isLoading: boolean
  error: string | null
  onSelectTeam(id: string): void
  onSubmit(): void
}

const ImportToOnfleetModalContentComponent = ({
  selectedTeams,
  teamList,
  isLoading,
  error,
  onSelectTeam,
  onSubmit,
}: Props) => {
  return (
    <>
      <Typography variant="h2">Import to Onfleet</Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        Add your drivers to your onfleet teams or hubs.{' '}
      </Typography>
      <TableContainer sx={{ maxHeight: 300 }}>
        <Table
          sx={{ minWidth: 750 }}
          stickyHeader
          aria-label="sticky table"
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: 'black' }} padding="checkbox"></TableCell>
              <TableCell style={{ backgroundColor: 'black' }} align="left" padding="normal">
                <Typography color="white" fontWeight={600}>
                  Team Name
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamList.map((row) => {
              const labelId = `enhanced-table-checkbox-${row.id}`
              const isItemSelected = selectedTeams.includes(row.id)

              return (
                <TableRow hover onClick={() => onSelectTeam(row.id)} tabIndex={-1} key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell id={labelId} align="left">
                    <Typography>{row.name}</Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        {error ? (
          <div className="mb-4 mt-4 text-center">
            <Typography variant="body1" color="error.main">
              {error}
            </Typography>
          </div>
        ) : null}
        <LoadingButton
          sx={{ width: '230px', marginTop: '44px' }}
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={selectedTeams.length === 0}
          loading={isLoading}
        >
          Assign Drivers
        </LoadingButton>
      </Box>{' '}
    </>
  )
}

export const ImportToOnfleetModalContent = React.memo(ImportToOnfleetModalContentComponent)
