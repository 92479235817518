import { memo } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PublicRoutes } from './components/routes/PublicRoutes'
import { SecureRoutes } from './components/routes/SecureRoutes'
import { getUser } from './selectors/auth'

export const AppRoutes = memo(() => {
  const user = useSelector(getUser)

  return <BrowserRouter>{user ? <SecureRoutes /> : <PublicRoutes />}</BrowserRouter>
})
