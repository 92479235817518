import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CreateCompany, SetOnboardingData } from 'src/constants/actionTypes'
import { ROUTES } from 'src/constants/routes'
import { getCompany } from 'src/selectors/company'
import { getOnboardingData } from 'src/selectors/onboarding'
import { colors } from 'src/theme'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { BackButton } from '../shared/BackButton'
import { ContinueButton } from '../shared/ContinueButton'
import { UploadFileIcon, UploadFileSuccessIcon } from '../shared/Icons'
import { ImageCropper } from '../shared/ImageCropper'

export const UploadLogoContent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onboardingData = useSelector(getOnboardingData)
  const company = useSelector(getCompany)

  const [logoCache, setLogoCache] = useState<string>('')
  const [logo, setLogo] = useState<string>(onboardingData.logo || '')
  const [fileName, setFileName] = useState<string>('')
  const [isDragOver, setIsDragOver] = useState<boolean>(false)

  const onSelectLogo = useCallback((e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      if (file.size <= 5000000) {
        const reader: any = new FileReader()
        reader.addEventListener('load', () => {
          setLogoCache(reader.result)
          setFileName(file.name)
        })
        reader.readAsDataURL(e.target.files[0])
      } else {
        alert('Image size should be less than 5MB')
      }
    }
  }, [])

  const removeLogoCache = useCallback(() => {
    setLogoCache('')
  }, [])
  const onCropLogo = useCallback((croppedLogo: string) => {
    setLogo(croppedLogo)

    removeLogoCache()
  }, [])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const onContinue = async () => {
    dispatch(SetOnboardingData.success({ logo }))

    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(dispatch, CreateCompany.request({ ...onboardingData, logo }))

      setIsLoading(false)

      navigate(ROUTES.OVERVIEW_URL)
    } catch (err: any) {
      const errorMessage =
        typeof err === 'string' ? err : err?.message ? err?.message : 'Failed to login'

      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (company) {
      navigate(ROUTES.OVERVIEW_URL)
    }
  }, [company])

  return (
    <div>
      <Box
        className="relative"
        sx={{
          border: '1px solid',
          borderRadius: '8px',
          padding: '100px 60px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: isDragOver ? colors.GRAY_LIGHT_COOL : colors.GRAY_WHITE,
          mb: 4,
          borderColor: isDragOver ? colors.BRAND_BLUE : '#DAD9E0',
          transition: '.3s ease',
        }}
      >
        {logo ? <UploadFileSuccessIcon /> : <UploadFileIcon />}
        <Box className="ml-8">
          <Typography variant="subtitle1">
            {logo ? 'File added.' : 'Customize your postings'}
          </Typography>
          <Typography>{logo ? fileName : 'Upload your company logo'}</Typography>
        </Box>
        <FileInput
          type="file"
          id="banner-file"
          name="file"
          accept="image/*"
          onChange={onSelectLogo}
          onDragEnter={() => setIsDragOver(true)}
          onDragLeave={() => setIsDragOver(false)}
        />
      </Box>
      <BackButton />
      {error ? (
        <div className="mb-4 text-center">
          <Typography variant="body1" color="error.main">
            {error}
          </Typography>
        </div>
      ) : null}
      <ContinueButton loading={isLoading} disabled={!logo} onClick={onContinue} />
      <ImageCropper image={logoCache} onClose={removeLogoCache} onCrop={onCropLogo} />
    </div>
  )
}

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
  -webkit-appearance: none;
  opacity: 0;
`
