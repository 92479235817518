import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { CampaignCreateLocationForm } from 'src/components/campaigns/campaign-create-location-form/CampaignCreateLocationForm'
import { SecurePage } from 'src/components/layout/SecurePage'
import { Progress } from 'src/components/shared/Progress'
import { getCampaignCreateData } from 'src/selectors/campaignCreate'

export const FirstCreateCampaignPage = () => {
  const campaignData = useSelector(getCampaignCreateData)

  return (
    <SecurePage>
      <div className="p-12">
        <Progress amount={2} current={1} />
        <Typography className="w-3/4 mt-8" variant="h1">
          Where do you need drivers?
        </Typography>
        <CampaignCreateLocationForm campaignData={campaignData} />
      </div>
    </SecurePage>
  )
}
