import { useFormik } from 'formik'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateCompany } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { AccountDetailsFormValues } from './AccountDetailsForm.interface'
import { validateForm } from './AccountDetailsForm.validator'
import LoadingButton from '@mui/lab/LoadingButton'
import { getCompany } from 'src/selectors/company'
import { Box, TextField, Typography } from '@mui/material'
import { BasicSelect } from 'src/components/shared/form/BasicSelect'
import { DISPATCH_TOOLS, DRIVER_FLEET_SIZE, WEEKLY_DELIVERY_VOLUME } from 'src/constants/onboarding'
import { convertStringsToSelectObjects } from 'src/utils/fields'
import { ImageCropper } from 'src/components/shared/ImageCropper'
import { UploadFileIcon } from 'src/components/shared/Icons'
import { colors } from 'src/theme'
import styled from '@emotion/styled'

export const AccountDetailsForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const [logoCache, setLogoCache] = useState<string>('')
  const [isDragOver, setIsDragOver] = useState<boolean>(false)

  const onSelectLogo = useCallback((e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      if (file.size <= 5000000) {
        const reader: any = new FileReader()
        reader.addEventListener('load', () => {
          setLogoCache(reader.result)
        })
        reader.readAsDataURL(e.target.files[0])
      } else {
        alert('Image size should be less than 5MB')
      }
    }
  }, [])

  const removeLogoCache = useCallback(() => {
    setLogoCache('')
  }, [])

  const dispatch = useDispatch()
  const company = useSelector(getCompany)

  const formInitValues: AccountDetailsFormValues = useMemo(
    () => ({
      driverFleet: company?.driverFleet,
      weeklyDeliveryVolume: company?.weeklyDeliveryVolume,
      dispatchTools: company?.dispatchTools,
      onfleetApiKey: company?.onfleetApiKey,
    }),
    [
      company?.driverFleet,
      company?.weeklyDeliveryVolume,
      company?.dispatchTools,
      company?.onfleetApiKey,
    ],
  )

  const formValidator = (values: AccountDetailsFormValues) => validateForm(values, values)

  const handleFormSubmit = async (values: AccountDetailsFormValues) => {
    setIsLoading(true)
    setError(null)

    if (company?.id) {
      await handleUpdateCompany(company?.id, values)
    }

    setIsLoading(false)
  }

  const formik = useFormik({
    initialValues: formInitValues,
    onSubmit: handleFormSubmit,
    validate: formValidator,
  })

  const handleUpdateCompany = async (companyId: string, values: AccountDetailsFormValues) => {
    try {
      await createAsyncAction(
        dispatch,
        UpdateCompany.request({
          id: companyId,
          ...values,
        }),
      )

      formik.resetForm()
    } catch (err: any) {
      const errorMessage =
        typeof err === 'string' ? err : err?.message ? err?.message : 'Failed to update partner'

      setError(errorMessage)
    }
  }

  const onCropLogo = useCallback((croppedLogo: string) => {
    formik.setFieldValue('logo', croppedLogo)

    removeLogoCache()
  }, [])

  return (
    <div>
      <div className="mb-4 mt-12">
        <Typography variant="subtitle1">Company Profile</Typography>
      </div>
      <div className="flex justify-between mt-9">
        <BasicSelect
          className="w-80 mr-8"
          onChange={formik.handleChange('dispatchTools')}
          onBlur={formik.handleBlur('dispatchTools')}
          value={formik.values.dispatchTools}
          error={Boolean(formik.touched.dispatchTools && formik.errors.dispatchTools)}
          helperText={formik.touched.dispatchTools && formik.errors.dispatchTools}
          label="Current dispatch tools"
          options={convertStringsToSelectObjects(DISPATCH_TOOLS)}
        />
        <BasicSelect
          className="w-80"
          onChange={formik.handleChange('driverFleet')}
          onBlur={formik.handleBlur('driverFleet')}
          value={formik.values.driverFleet}
          error={Boolean(formik.touched.driverFleet && formik.errors.driverFleet)}
          helperText={formik.touched.driverFleet && formik.errors.driverFleet}
          label="Current fleet size"
          options={convertStringsToSelectObjects(DRIVER_FLEET_SIZE)}
        />
      </div>
      <div className="flex justify-between mt-9">
        <BasicSelect
          className="w-80"
          onChange={formik.handleChange('weeklyDeliveryVolume')}
          onBlur={formik.handleBlur('weeklyDeliveryVolume')}
          value={formik.values.weeklyDeliveryVolume}
          error={Boolean(formik.touched.weeklyDeliveryVolume && formik.errors.weeklyDeliveryVolume)}
          helperText={formik.touched.weeklyDeliveryVolume && formik.errors.weeklyDeliveryVolume}
          label="Weekly Deliver Volume"
          options={convertStringsToSelectObjects(WEEKLY_DELIVERY_VOLUME)}
        />
      </div>

      <div className="mb-4 mt-12">
        <Typography variant="subtitle1">Onfleet API Key</Typography>
      </div>
      <TextField
        variant="outlined"
        className="w-80 mt-6"
        onChange={formik.handleChange('onfleetApiKey')}
        onBlur={formik.handleBlur('onfleetApiKey')}
        value={formik.values.onfleetApiKey}
        error={Boolean(formik.touched.onfleetApiKey && formik.errors.onfleetApiKey)}
        helperText={formik.touched.onfleetApiKey && formik.errors.onfleetApiKey}
        label="Onfleet API Key"
      />

      <div className="mb-4 mt-12">
        <Typography variant="subtitle1">Company Logo</Typography>
      </div>
      <label htmlFor="banner-file">
        <Box
          className="relative"
          sx={{
            border: '1px solid',
            borderRadius: '8px',
            padding: '100px 60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: isDragOver ? colors.GRAY_LIGHT_COOL : colors.GRAY_WHITE,
            mb: 4,
            borderColor: isDragOver ? colors.BRAND_BLUE : '#DAD9E0',
            transition: '.3s ease',
          }}
        >
          <UploadFileIcon />
          <Box className="ml-8">
            {!formik.values?.logo && (
              <Typography fontWeight={600}>Update your company logo</Typography>
            )}
            <Typography>
              {formik.values?.logo ? 'Updated logo is ready to be saved' : 'logo.png'}
            </Typography>
          </Box>
          <FileInput
            type="file"
            id="banner-file"
            name="file"
            accept="image/*"
            onChange={onSelectLogo}
            onDragEnter={() => setIsDragOver(true)}
            onDragLeave={() => setIsDragOver(false)}
          />
        </Box>
      </label>

      <div>
        {error ? (
          <div className="mb-4 text-center">
            <Typography variant="subtitle1" color="error.main">
              {error}
            </Typography>
          </div>
        ) : null}
        <LoadingButton
          className="w-60 mt-12"
          variant="contained"
          loading={isLoading}
          onClick={() => formik.handleSubmit()}
          disabled={!Object.values(formik.touched).some((item) => !!item) && !formik.values?.logo}
        >
          Save
        </LoadingButton>
      </div>
      <input
        type="file"
        id="banner-file"
        name="file"
        accept="image/*"
        onChange={onSelectLogo}
        hidden
      />
      <ImageCropper image={logoCache} onClose={removeLogoCache} onCrop={onCropLogo} />
    </div>
  )
}

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
  -webkit-appearance: none;
  opacity: 0;
`
