import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { SecurePage } from 'src/components/layout/SecurePage'
import {
  FirstCampaignCreatedModal,
  STORAGE_FIRST_CAMPAIGN_CREATED_SHOWN_KEY,
} from 'src/components/campaigns/FirstCampaignCreatedModal'
import { useDispatch, useSelector } from 'react-redux'
import { getCampaigns } from 'src/selectors/campaigns'
import { CampaignsTableContainer } from 'src/components/campaigns/campaigns-table/CampaignsTableСontainer'
import { FetchCampaigns } from 'src/constants/actionTypes'

export const CampaignsPage = () => {
  const dispatch = useDispatch()

  const campaigns = useSelector(getCampaigns)

  const [showStartTrialModal, setShowStartTrialModal] = useState(
    !localStorage.getItem(STORAGE_FIRST_CAMPAIGN_CREATED_SHOWN_KEY) && campaigns.data.length === 1,
  )

  useEffect(() => {
    dispatch(
      FetchCampaigns.request({
        limit: 5,
        offset: 0,
      }),
    )
  }, [])

  const handleCampaignsPaginationChange = (limit: number, offset: number, search: string) => {
    dispatch(
      FetchCampaigns.request({
        limit,
        offset,
        search,
      }),
    )
  }

  return (
    <SecurePage>
      <div className="w-full px-8 py-12">
        <Typography variant="h1">Campaigns</Typography>
        <CampaignsTableContainer
          campaigns={campaigns}
          limit={5}
          onPaginationChange={handleCampaignsPaginationChange}
        />
      </div>
      <FirstCampaignCreatedModal
        open={showStartTrialModal}
        onClose={() => {
          setShowStartTrialModal(false)
        }}
      />
    </SecurePage>
  )
}
