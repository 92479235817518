import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import { memo } from 'react'

const ContinueButtonComponent = (props: LoadingButtonProps) => (
  <LoadingButton className="mr-6 w-60" variant="contained" {...props}>
    Continue
  </LoadingButton>
)

export const ContinueButton = memo(ContinueButtonComponent)
