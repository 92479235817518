import Typography from '@mui/material/Typography'
import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteCampaignCreateData, CreateCampaign, UpdateCampaign } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { CampaignCreateJobDetailsFormValues } from './CampaignCreateJobDetailsForm.interface'
import { validateForm } from './CampaignCreateJobDetailsForm.validator'
import LoadingButton from '@mui/lab/LoadingButton'
import { CampaignCreateJobDetailsFormContent } from './CampaignCreateJobDetailsFormContent'
import { BannerPreview } from './BannerPreview'
import { JobPreview } from './JobPreview'
import { CampaignCreateState } from 'src/reducers/campaign-create/types'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { getCompany } from 'src/selectors/company'

interface Props {
  campaignData: CampaignCreateState
  campaignId?: string
}

export const CampaignCreateJobDetailsForm = ({ campaignData, campaignId }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const company = useSelector(getCompany)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const formValidator = (values: CampaignCreateJobDetailsFormValues) => validateForm(values, values)

  const handleFormSubmit = (values: CampaignCreateJobDetailsFormValues) => {
    setIsLoading(true)
    setError(null)

    if (campaignId) {
      handleUpdateCampaign(campaignId, values)
    } else {
      handleCreateCampaign(values)
    }
  }

  const handleCreateCampaign = async (values: CampaignCreateJobDetailsFormValues) => {
    try {
      await createAsyncAction(
        dispatch,
        CreateCampaign.request({
          ...campaignData,
          ...values,
        }),
      )

      dispatch(DeleteCampaignCreateData.success())

      navigate(ROUTES.CAMPAIGNS_URL)
    } catch (err: any) {
      const errorMessage =
        typeof err === 'string' ? err : err?.message ? err?.message : 'Failed to post campaign'

      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdateCampaign = async (
    campaignId: string,
    values: CampaignCreateJobDetailsFormValues,
  ) => {
    try {
      await createAsyncAction(
        dispatch,
        UpdateCampaign.request({
          id: campaignId,
          ...campaignData,
          ...values,
        }),
      )

      dispatch(DeleteCampaignCreateData.success())

      navigate(ROUTES.CAMPAIGN_URL.replace(':id', campaignId))
    } catch (err: any) {
      const errorMessage =
        typeof err === 'string' ? err : err?.message ? err?.message : 'Failed to post campaign'

      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={{ ...campaignData }}
      onSubmit={handleFormSubmit}
      validate={formValidator}
    >
      {({ values, handleSubmit }) => (
        <div className="flex">
          <div className="w-1/3 pb-8">
            <CampaignCreateJobDetailsFormContent />
          </div>
          <div className="w-2/3 flex flex-col items-center">
            <div className="flex justify-center">
              <BannerPreview
                title={values.cardTitle}
                copy={values.cardContent}
                logoUrl={company?.logoUrl}
              />
              <JobPreview
                companyName={company?.displayName}
                title={values.pageTitle}
                description={values.pageContent}
                price={values.price}
                priceUnit={values.priceUnit}
                requirements={values.requirements}
              />
            </div>
            {error ? (
              <div className="mt-4 text-center">
                <Typography variant="body1" color="error.main">
                  {error}
                </Typography>
              </div>
            ) : null}
            <LoadingButton
              className="w-1/2 mt-8"
              variant="contained"
              loading={isLoading}
              onClick={() => handleSubmit()}
            >
              {campaignId ? 'Update Campaign' : 'Create Campaign'}
            </LoadingButton>
          </div>
        </div>
      )}
    </Formik>
  )
}
