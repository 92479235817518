import Typography from '@mui/material/Typography'
import { AuthWelcomeContentListItem } from './AuthWelcomeContentListItem'

export const AuthWelcomeContent = () => {
  return (
    <div>
      <Typography variant="h1">
        Hire experienced delivery drivers, in your city, in days not weeks.
      </Typography>
      <Typography className="mt-4" variant="subtitle1" fontWeight={600}>
        ParaPartners makes finding drivers and building your fleet a breeze for all delivery based
        teams.
      </Typography>
      <div className="pt-6">
        <AuthWelcomeContentListItem
          image={require('../../../assets/images/auth-welcome-1.png')}
          title="Reach 100K+ drivers across 20+ cities in the US."
          subtitle="Target recruitment  in cities according to our business needs. "
        />
        <AuthWelcomeContentListItem
          image={require('../../../assets/images/auth-welcome-2.png')}
          title="Drivers are pre-screened for relevant experience"
          subtitle="Drivers pre-screened for experience on major delivery platforms, e.g. Doordash, UberEats and Grubhub"
        />
        <AuthWelcomeContentListItem
          image={require('../../../assets/images/auth-welcome-3.png')}
          title="Immediately reduce your driver onboarding time"
          subtitle="Most drivers on our platform respond within 24 to 48 hours."
        />
      </div>
    </div>
  )
}
