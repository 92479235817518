import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { ForgotPassword, RestorePassword } from 'src/constants/actionTypes'
import { ApiService, callApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'

interface ForgotPasswordRequest {
  email: string
}

function* onForgotPassword(action: AsyncAction<ForgotPasswordRequest>) {
  const { email } = action.payload

  try {
    yield call(callApi, Endpoints.ForgotPassword, {
      method: 'POST',
      body: {
        email,
      },
      apiService: ApiService.Paraworks,
    })

    yield put(ForgotPassword.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(ForgotPassword.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface RestorePasswordRequest {
  password: string
  token: string
}

function* onRestorePassword(action: AsyncAction<RestorePasswordRequest>) {
  const { password, token } = action.payload

  try {
    yield call(callApi, Endpoints.RestorePassword, {
      method: 'POST',
      body: {
        password,
        forgotPasswordToken: token,
      },
      apiService: ApiService.Paraworks,
    })

    yield put(RestorePassword.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(RestorePassword.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* forgotPasswordSaga() {
  yield takeEvery(ForgotPassword.type.REQUEST, onForgotPassword)
  yield takeEvery(RestorePassword.type.REQUEST, onRestorePassword)
}
