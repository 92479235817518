import { fork } from 'redux-saga/effects'
import { forgotPasswordSaga } from './forgot-password'
import { loginSaga } from './login'
import { registerSaga } from './register'
import { userSaga } from './user'

export function* authSaga() {
  yield fork(loginSaga)
  yield fork(registerSaga)
  yield fork(userSaga)
  yield fork(forgotPasswordSaga)
}
