import Typography from '@mui/material/Typography'
import { RegisterForm } from 'src/components/auth/register-form/RegisterForm'
import { AuthWelcomeContainer } from 'src/components/layout/AuthWelcomeContainer'
import { PublicPage } from 'src/components/layout/PublicPage'
import { colors } from 'src/theme'

export const RegisterPage = () => {
  return (
    <PublicPage>
      <AuthWelcomeContainer>
        <Typography variant="h3" fontWeight={600} color={colors.BRAND_BLUE}>
          Get started today.
        </Typography>
        <Typography className="mb-6 mt-2" variant="subtitle1">
          Create your account.
        </Typography>
        <RegisterForm />
      </AuthWelcomeContainer>
    </PublicPage>
  )
}
