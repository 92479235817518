import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { CampaignCreateJobDetailsForm } from 'src/components/campaigns/campaign-create-job-details-form/CampaignCreateJobDetailsForm'
import { SecurePage } from 'src/components/layout/SecurePage'
import { Progress } from 'src/components/shared/Progress'
import { getCampaignCreateData } from 'src/selectors/campaignCreate'

export const SecondCreateCampaignPage = () => {
  const campaignData = useSelector(getCampaignCreateData)

  return (
    <SecurePage>
      <div className="p-12">
        <Progress amount={2} current={2} />
        <Typography className="w-3/4 mt-8" variant="h1">
          Job Details
        </Typography>
        <CampaignCreateJobDetailsForm campaignData={campaignData} />
      </div>
    </SecurePage>
  )
}
