import { ReactNode } from 'react'
import { SecureLeftMenu } from '../left-menu'
import { CompanyExist } from '../redirect-hoc/CompanyExist'
import { PublicFooter } from './PublicFooter'
import { PublicHeader } from './PublicHeader'

interface Props {
  children: ReactNode
}

export const SecurePage = ({ children }: Props) => {
  return (
    <div className="h-full flex flex-col">
      <div className="flex-1">
        <PublicHeader />
        <CompanyExist>
          <div className="flex h-full">
            <SecureLeftMenu />
            {children}
          </div>
        </CompanyExist>
      </div>
      <PublicFooter />
    </div>
  )
}
