import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FetchCompany, FetchLocations } from './constants/actionTypes'

export const SecureHooksContainer = memo(() => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(FetchCompany.request())
    dispatch(FetchLocations.request())
  }, [])

  return <div />
})
