import { composeValidators, createValidator, stateValidator } from 'src/utils/validation/core'
import { isPasswordValid, isRequired } from 'src/utils/validation/validator'

const isConfirmPasswordValid = (value: string, context: { password: string }) =>
  value === context.password

export const validateForm = stateValidator({
  password: composeValidators([
    createValidator(isRequired, 'Password is required'),
    createValidator(isPasswordValid, 'Password is not valid. Must be 8 or more characters'),
  ]),
  confirmPassword: composeValidators([
    createValidator(isRequired, 'Password confirmation is required'),
    createValidator(isConfirmPasswordValid, 'Passwords should match'),
  ]),
})
