import { fork } from 'redux-saga/effects'
import { authSaga } from './auth'
import { companySaga } from './company'
import { campaignsSaga } from './campaigns'
import { applicantsSaga } from './applicants'
import { onfleetSaga } from './onfleet'
import { dynamicLinksSaga } from './dynamic-links'

export function* rootSaga() {
  yield fork(authSaga)
  yield fork(companySaga)
  yield fork(campaignsSaga)
  yield fork(applicantsSaga)
  yield fork(onfleetSaga)
  yield fork(dynamicLinksSaga)
}
