import React from 'react'
import { Box, Modal } from '@mui/material'
import { OnfleetTeam } from 'src/interfaces/onfleet'
import { useSelector } from 'react-redux'
import { getCompany } from 'src/selectors/company'
import { ImportToOnfleetModalContent } from '../import-to-onfleet-modal-content/ImportToOnfleetModalContent'
import { EmptyTeamsModalContent } from '../import-to-onfleet-modal-content/EmptyTeamsModalContent'
import { NoOnfleetKeyModalContent } from '../import-to-onfleet-modal-content/NoOnfleetKeyModalContent'
import { ImportToOnfleetModalSuccessContent } from '../import-to-onfleet-modal-content/ImportToOnfleetModalSuccessContent'

interface Props {
  isOpen: boolean
  selectedTeams: Array<string>
  teamList: Array<OnfleetTeam>
  isLoading: boolean
  error: string | null
  importSuccess: boolean
  onSelectTeam(id: string): void
  onClose(): void
  onSubmit(): void
}

const ImportToOnfleetModalComponent = ({
  isOpen,
  selectedTeams,
  teamList,
  isLoading,
  error,
  importSuccess,
  onSelectTeam,
  onClose,
  onSubmit,
}: Props) => {
  const company = useSelector(getCompany)

  const handleClose = () => {
    onClose()
  }

  let content = (
    <ImportToOnfleetModalContent
      selectedTeams={selectedTeams}
      teamList={teamList}
      isLoading={isLoading}
      error={error}
      onSelectTeam={onSelectTeam}
      onSubmit={onSubmit}
    />
  )

  if (importSuccess) {
    content = <ImportToOnfleetModalSuccessContent onClose={handleClose} />
  }

  if (!teamList.length) {
    content = <EmptyTeamsModalContent onClose={handleClose} />
  }

  if (!company?.onfleetApiKey) {
    content = <NoOnfleetKeyModalContent onClose={handleClose} />
  }

  return (
    <Modal
      container={() => document.getElementById('root')}
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '780px',
          bgcolor: 'background.paper',
          p: '50px 60px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {content}
      </Box>
    </Modal>
  )
}

export const ImportToOnfleetModal = React.memo(ImportToOnfleetModalComponent)
