import { Logout, FetchOnfleetTeams } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { OnfleetState } from './types'

const getInitialState = (): OnfleetState => ({
  teamList: [],
})

export const onfleetReducer = (
  state: OnfleetState = getInitialState(),
  action: Action<any>,
): OnfleetState => {
  switch (action.type) {
    case FetchOnfleetTeams.type.SUCCESS:
      return {
        ...state,
        teamList: action.payload,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
