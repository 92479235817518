import { Typography, Link } from '@mui/material'
import { OnboardingContainer } from 'src/components/layout/OnboardingContainer'
import { PublicPage } from 'src/components/layout/PublicPage'
import { EnterOnfleetForm } from 'src/components/onboarding/enter-onfleet-key-form/EnterOnfleetForm'
import { colors } from 'src/theme'

export const EnterOnfleetOnboardingPage = () => {
  return (
    <PublicPage>
      <OnboardingContainer
        title="Enter your Onfleet API Key."
        subtitle={
          <>
            <Link
              variant="body1"
              fontWeight={600}
              color={colors.BRAND_BLUE}
              href={process.env.REACT_APP_ONFLEET_FIND_API_KEY_URL}
              target="_blank"
              underline="none"
            >
              Find my API Key.
            </Link>
            <Typography variant="body1">
              &nbsp;We use your onfleet API Key to link applicant data directly from ParaPartners.
            </Typography>
          </>
        }
        screenNumber={3}
      >
        <EnterOnfleetForm />
      </OnboardingContainer>
    </PublicPage>
  )
}
