import Typography from '@mui/material/Typography'
import { ForgotPasswordForm } from 'src/components/auth/forgot-password-form/ForgotPasswordForm'
import { AuthFormContainer } from 'src/components/layout/AuthFormContainer'
import { PublicPage } from 'src/components/layout/PublicPage'
import { colors } from 'src/theme'

export const ForgotPasswordPage = () => {
  return (
    <PublicPage>
      <AuthFormContainer>
        <Typography variant="h3" fontWeight={600} color={colors.BRAND_BLUE}>
          Forgot Password
        </Typography>
        <Typography className="mb-6 mt-2" variant="subtitle1">
          Enter email address to reset your password
        </Typography>
        <ForgotPasswordForm />
      </AuthFormContainer>
    </PublicPage>
  )
}
