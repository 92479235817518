import { User } from 'src/interfaces/user'

class BaseUserService {
  private STORAGE_USER_KEY = 'user'
  private STORAGE_USER_TOKEN_KEY = 'userToken'

  public setStoredUser(user: User | null) {
    if (!user) {
      localStorage.removeItem(this.STORAGE_USER_KEY)
      return
    }

    localStorage.setItem(this.STORAGE_USER_KEY, JSON.stringify(user))
  }

  public getStoredUser(): User | null {
    let user: User | null = null

    const data = localStorage.getItem(this.STORAGE_USER_KEY) || ''

    try {
      user = JSON.parse(data)
    } catch (err) {
      return null
    }

    return user
  }

  public setStoredUserToken(userToken: string | null) {
    if (!userToken) {
      localStorage.removeItem(this.STORAGE_USER_TOKEN_KEY)
      return
    }

    localStorage.setItem(this.STORAGE_USER_TOKEN_KEY, userToken)
  }

  public getStoredUserToken(): string | null {
    const userToken = localStorage.getItem(this.STORAGE_USER_TOKEN_KEY)

    return userToken
  }
}

export const UserService = new BaseUserService()
