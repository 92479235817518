import { Button, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ApplicantsTable } from './ApplicantsTable'
import debounce from 'lodash.debounce'
import { ImportToOnfleetModal } from './ImportToOnfleetModal'
import { ApplicantsList } from 'src/reducers/applicants/types'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { getOnfleetTeams } from 'src/selectors/onfleet'
import { FetchOnfleetTeams, ImportApplicantsToOnfleet } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'

interface Props {
  applicants: ApplicantsList
  limit?: number
  onPaginationChange?(limit: number, offset: number, search: string): void
}

export const ApplicantsTableContainer = ({ applicants, limit, onPaginationChange }: Props) => {
  const dispatch = useDispatch()

  const teamList = useSelector(getOnfleetTeams)

  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(limit || 5)
  const [selectedApplicants, setSelectedApplicants] = useState<string[]>([])
  const [selectedTeams, setSelectedTeams] = useState<string[]>([])
  const [showImportToOnfleetModal, setShowImportToOnfleetModal] = useState<boolean>(false)
  const [isImportLoading, setIsImportLoading] = useState<boolean>(false)
  const [importError, setImportError] = useState<string | null>(null)
  const [showImportSuccess, setShowImportSuccess] = useState<boolean>(false)

  useEffect(() => {
    dispatch(FetchOnfleetTeams.request())
  }, [])

  const navigate = useNavigate()

  const handleClick = (id: string) => {
    navigate(ROUTES.APPLICANT_URL.replace(':id', id))
  }

  const handleCheck = (id: string, forceSelect?: boolean) => {
    const selectedIndex = selectedApplicants.indexOf(id)

    if (selectedIndex === -1) {
      setSelectedApplicants((state) => [...state, id])
    } else if (forceSelect) {
      return
    } else {
      setSelectedApplicants((state) => [
        ...state.slice(0, selectedIndex),
        ...state.slice(selectedIndex + 1),
      ])
    }
  }

  const handleTeamSelect = (id: string) => {
    const selectedIndex = selectedTeams.indexOf(id)

    if (selectedIndex === -1) {
      setSelectedTeams((state) => [...state, id])
    } else {
      setSelectedTeams((state) => [
        ...state.slice(0, selectedIndex),
        ...state.slice(selectedIndex + 1),
      ])
    }
  }

  const handlePageChange = (page: number) => {
    if (onPaginationChange) {
      onPaginationChange(rowsPerPage, page * rowsPerPage, search)
    }

    setPage(page)
  }

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    if (onPaginationChange) {
      onPaginationChange(rowsPerPage, 0, search)
    }

    setRowsPerPage(rowsPerPage)
    setPage(0)
  }

  const handleSetSearch = (value: string) => {
    if (onPaginationChange) {
      onPaginationChange(rowsPerPage, 0, value)
    }

    setSearch(value)
  }

  const handleSubmitImport = async () => {
    setIsImportLoading(true)
    setImportError(null)

    try {
      await createAsyncAction(
        dispatch,
        ImportApplicantsToOnfleet.request({
          idList: selectedApplicants,
          teamList: selectedTeams,
        }),
      )

      handlePageChange(0)
      setSelectedApplicants([])
      setShowImportSuccess(true)
    } catch (err: any) {
      setImportError(err)
    } finally {
      setIsImportLoading(false)
    }
  }

  const handleShowImportToOnfleetModal = () => {
    setImportError(null)
    setSelectedTeams([])
    setShowImportToOnfleetModal(true)
  }

  const handleCloseImportToOnfleetModal = () => {
    setShowImportToOnfleetModal(false)
    setShowImportSuccess(false)
  }

  return (
    <div>
      <div className="flex justify-between my-8">
        <div className="w-1/3 flex items-center">
          <Typography variant="subtitle1">Applicants</Typography>
          <TextField
            variant="outlined"
            className="w-96 ml-8"
            onChange={debounce((e: any) => handleSetSearch(e.target.value), 500)}
            label="Search Applicants"
          />
        </div>
        <Button
          disabled={selectedApplicants.length === 0}
          variant="outlined"
          color="secondary"
          onClick={handleShowImportToOnfleetModal}
        >
          Import Drivers to Onfleet
        </Button>
      </div>
      <ApplicantsTable
        applicants={applicants.data}
        page={page}
        rowsPerPage={rowsPerPage}
        total={applicants.total}
        onClick={handleClick}
        onCheck={handleCheck}
        selected={selectedApplicants}
        setPage={handlePageChange}
        setRowsPerPage={handleRowsPerPageChange}
        onImportToOnfleet={handleShowImportToOnfleetModal}
      />
      <ImportToOnfleetModal
        isOpen={showImportToOnfleetModal}
        onClose={handleCloseImportToOnfleetModal}
        selectedTeams={selectedTeams}
        teamList={teamList}
        isLoading={isImportLoading}
        error={importError}
        importSuccess={showImportSuccess}
        onSelectTeam={handleTeamSelect}
        onSubmit={handleSubmitImport}
      />
    </div>
  )
}
