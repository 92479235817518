import { FormHelperText } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

interface Props {
  className?: string
  label?: string
  value?: string | number
  error?: boolean
  helperText?: string | false
  options: Array<BasicSelectOption>
  onChange(e: React.ChangeEvent<any>): void
  onBlur?: (e: React.FocusEvent<any>) => void
}

export interface BasicSelectOption {
  label: string
  value: string | number
}

export const BasicSelect = ({
  className,
  label,
  value,
  error,
  helperText,
  options,
  onChange,
  onBlur,
}: Props) => {
  const handleChange = (event: any) => {
    onChange(event)
  }

  const handleBlur = (event: any) => {
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <FormControl className={className} fullWidth error={error}>
      {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  )
}
