import { LoadingButton } from '@mui/lab'
import { Button, Link, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateCompany } from 'src/constants/actionTypes'
import { getCompany } from 'src/selectors/company'
import { colors } from 'src/theme'
import { createAsyncAction } from 'src/utils/reduxUtils'

interface Props {
  onClose: () => void
}

export const OnfleetCard = ({ onClose }: Props) => {
  const dispatch = useDispatch()
  const company = useSelector(getCompany)

  const [onfleetApiKey, setApiKey] = useState('')
  const [entered, setEntered] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const onEnter = async () => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(dispatch, UpdateCompany.request({ ...company, onfleetApiKey }))
      setEntered(true)
    } catch (err: any) {
      const errorMessage =
        typeof err === 'string' ? err : err?.message ? err?.message : 'Failed to post campaign'

      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      className="px-6 py-8 flex-1 mr-8 max-w-lg"
      style={{
        backgroundColor: 'white',
        boxShadow: `0px 4px 16px rgba(0, 0, 0, 0.07)`,
        borderRadius: '4px',
      }}
    >
      {entered ? (
        <>
          <Typography variant="h4">
            Success your API Key has been successfully connected!
          </Typography>
          <Typography fontWeight={500} className="mt-2">
            You can now import driver data directly to onfleet from your ParaPartner recruitment
            campaigns.
          </Typography>
          <Button onClick={onClose} color="secondary" variant="outlined" className="mt-16 w-60">
            Close
          </Button>
        </>
      ) : (
        <>
          <Typography className="w-3/4" variant="h4">
            Create or connect your Onfleet account.
          </Typography>
          <Typography fontWeight={500} className="mt-2">
            We use your onfleet API Key to link applicant data directly
          </Typography>
          <div className="flex">
            <Typography fontWeight={500}>from ParaPartners.&nbsp;</Typography>
            <Link
              fontWeight={600}
              color={colors.BRAND_BLUE}
              href={process.env.REACT_APP_ONFLEET_FIND_API_KEY_URL}
              target="_blank"
              underline="none"
            >
              Find my API Key.
            </Link>
          </div>
          <TextField
            variant="outlined"
            className="w-full my-6"
            onChange={(e) => setApiKey(e.target.value)}
            label="Onfleet API Key"
          />
          {error ? (
            <div className="mb-4 text-center">
              <Typography variant="subtitle1" color="error.main">
                {error}
              </Typography>
            </div>
          ) : null}
          <div className="flex space-x-4">
            <Button
              color="secondary"
              variant="outlined"
              target="_blank"
              component="a"
              className="flex-1 mr-2"
              href={process.env.REACT_APP_ONFLEET_FIND_API_KEY_URL}
            >
              Create Onfleet Account
            </Button>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              disabled={!onfleetApiKey}
              className="flex-1"
              onClick={onEnter}
            >
              Enter API Key
            </LoadingButton>
          </div>
        </>
      )}
    </div>
  )
}
