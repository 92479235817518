import { ApplicantDriverPlatformData } from 'src/interfaces/applicants'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import {
  ApplicantDriverPlatformDataOnTimeCategoryDictionary,
  ApplicantDriverPlatformDataType,
} from 'src/constants/applicants'
import { DoorDashIcon } from 'src/components/shared/Icons'
import { transformToFixedWithoutEndZero } from 'src/utils/transformers'

interface Props {
  applicantPlatformData: ApplicantDriverPlatformData
}

const Item = ({ title, value }: { title: string; value?: string }) => (
  <div
    className="flex justify-between items-center w-52 pb-2"
    style={{ borderBottom: '1px solid #DAD9E0' }}
  >
    <Typography color={colors.GRAY_DARK_COOL} variant="body1" fontWeight={600}>
      {`${title}: `}
    </Typography>
    <Typography color={colors.GRAY_DARK_COOL} fontWeight={600}>
      {value || ''}
    </Typography>
  </div>
)

export const ApplicantPlatformInfo = ({ applicantPlatformData }: Props) => {
  return (
    <div className="items-start flex flex-col mt-6">
      <div
        className="py-4 px-6 mb-5"
        style={{
          backgroundColor: 'white',
          border: '1px solid #DAD9E0',
          borderRadius: '8px',
        }}
      >
        <Typography className="mb-5" variant="subtitle1">
          Car Details
        </Typography>
        <div className="flex space-x-12">
          <Item title="Make" value={applicantPlatformData?.carMake} />
          <Item title="Model" value={applicantPlatformData?.carModel} />
        </div>
      </div>

      <div
        className="py-4 px-6 mb-5"
        style={{
          backgroundColor: 'white',
          border: '1px solid #DAD9E0',
          borderRadius: '8px',
        }}
      >
        {applicantPlatformData.platform === ApplicantDriverPlatformDataType.Doordash && (
          <div className="flex space-x-2 items-center mb-5">
            <DoorDashIcon />
            <Typography variant="subtitle1">DoorDash</Typography>
          </div>
        )}
        <div className="flex space-x-12 mt-4">
          <Item
            title="Customer Rating"
            value={
              applicantPlatformData?.customerRating && `${applicantPlatformData?.customerRating} ☆`
            }
          />
          <Item
            title="Completion Rate"
            value={
              applicantPlatformData?.completionRate &&
              `${transformToFixedWithoutEndZero(
                parseFloat(applicantPlatformData?.completionRate) * 100,
                2,
              )}%`
            }
          />
        </div>
        <div className="flex space-x-12 my-4">
          <Item title="Deliveries Completed" value={applicantPlatformData?.lifetimeTrips} />
          <Item
            title="Ontime Rate"
            value={
              applicantPlatformData?.ontimeScore &&
              `${transformToFixedWithoutEndZero(
                parseFloat(applicantPlatformData?.ontimeScore) * 100,
                2,
              )}%`
            }
          />
        </div>
        <Item
          title="Ontime Category"
          value={
            applicantPlatformData?.ontimeCategory &&
            ApplicantDriverPlatformDataOnTimeCategoryDictionary[
              applicantPlatformData?.ontimeCategory
            ]
          }
        />
      </div>
    </div>
  )
}
