import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getUserToken } from 'src/selectors/auth'

export const useIsAuthorized = () => {
  const userToken = useSelector(getUserToken)

  const isAuthorized = useMemo(() => {
    return Boolean(userToken)
  }, [userToken])

  return isAuthorized
}
