import React, { useState } from 'react'
import { Box, Modal, Button, Typography, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Formik } from 'formik'
import { validateForm } from './AccountChangePasswordModalForm.validator'
import { AccountChangePasswordModalFormValues } from './AccountChangePasswordModalForm.interface'
import { useDispatch } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { UpdatePassword } from 'src/constants/actionTypes'

interface Props {
  isOpen: boolean
  onClose(): void
}

const AccountChangePasswordModalComponent = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  const formValidator = (values: AccountChangePasswordModalFormValues) =>
    validateForm(values, values)

  const handleFormSubmit = async (values: AccountChangePasswordModalFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(dispatch, UpdatePassword.request(values))
      setShowSuccess(true)
    } catch (err: any) {
      const errorMessage =
        typeof err === 'string' ? err : err?.message ? err?.message : 'Failed to login'

      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      container={() => document.getElementById('root')}
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '780px',
          bgcolor: 'background.paper',
          p: '50px 150px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {showSuccess ? (
          <>
            <Typography variant="h2">Success! Your password has been updated.</Typography>
            <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
              You have also been signed out of all other devices and will need to log in with this
              new password.{' '}
            </Typography>
            <Button
              sx={{ mr: 2, width: '230px' }}
              color="primary"
              variant="contained"
              onClick={() => {
                setShowSuccess(false)
                onClose()
              }}
            >
              Done
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h2">Update Account Credentials</Typography>
            <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
              Choose a strong password and don’t reuse it for other accounts. Changing your password
              will sign you out of all other devices.{' '}
            </Typography>
            <Formik
              initialValues={{
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: '',
              }}
              onSubmit={handleFormSubmit}
              validate={formValidator}
            >
              {({ values, handleSubmit, touched, errors, handleChange, handleBlur }) => (
                <div>
                  <TextField
                    className="w-full mb-8"
                    label="Old Password"
                    type="password"
                    onChange={handleChange('oldPassword')}
                    onBlur={handleBlur('oldPassword')}
                    value={values.oldPassword}
                    error={Boolean(touched.oldPassword && errors.oldPassword)}
                    helperText={touched.oldPassword && errors.oldPassword}
                  />
                  <TextField
                    className="w-full mb-8"
                    label="New Password"
                    type="password"
                    onChange={handleChange('newPassword')}
                    onBlur={handleBlur('newPassword')}
                    value={values.newPassword}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                  />
                  <TextField
                    className="w-full mb-8"
                    label="Confirm New Password"
                    type="password"
                    onChange={handleChange('confirmNewPassword')}
                    onBlur={handleBlur('confirmNewPassword')}
                    value={values.confirmNewPassword}
                    error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
                    helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                  />
                  {error ? (
                    <div className="mb-8 text-center">
                      <Typography variant="body1" color="error.main">
                        {error}
                      </Typography>
                    </div>
                  ) : null}
                  <Box>
                    <Button
                      sx={{ mr: 2, width: '230px' }}
                      color="secondary"
                      variant="outlined"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      sx={{ width: '230px' }}
                      loading={isLoading}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        handleSubmit()
                      }}
                    >
                      Update Password
                    </LoadingButton>
                  </Box>
                </div>
              )}
            </Formik>
          </>
        )}
      </Box>
    </Modal>
  )
}

export const AccountChangePasswordModal = React.memo(AccountChangePasswordModalComponent)
