import { useRef, useState } from 'react'
import { ButtonGroup, Menu, MenuItem } from '@mui/material'
import {
  ApplicantContactStatus,
  ApplicantContactStatusDictionary,
  CONTACT_STATUS_SELECT_LIST,
} from 'src/constants/applicants'
import { ArrowDropDown } from '@mui/icons-material'
import { colors } from 'src/theme'
import { LoadingButton } from '@mui/lab'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { UpdateApplicant } from 'src/constants/actionTypes'
import { useDispatch } from 'react-redux'

interface Props {
  contactStatus: ApplicantContactStatus
  applicantId: string
}

export const ContactStatusSelect = ({ applicantId, contactStatus }: Props) => {
  const dispatch = useDispatch()
  const anchorRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(
    contactStatus ? ApplicantContactStatusDictionary[contactStatus] : '',
  )

  const onChange = async (contactStatus: ApplicantContactStatus, label: string) => {
    try {
      setLoading(true)

      await createAsyncAction(dispatch, UpdateApplicant.request({ id: applicantId, contactStatus }))

      setStatus(label)
    } catch (err) {
      // handle err here - show toast
    }

    setLoading(false)
    setOpen(false)
  }

  return (
    <ButtonGroup variant="contained" ref={anchorRef}>
      <LoadingButton
        sx={{
          backgroundColor: colors.GRAY_DARK_COOL,
          color: 'white',
          padding: '8px',
          borderRadius: '4px',
          height: '40px',
          fontWeight: 500,
        }}
        onClick={(e) => {
          e.stopPropagation()
          setOpen(true)
        }}
        loading={loading}
        endIcon={<ArrowDropDown />}
        color="secondary"
        variant="contained"
      >
        {status}
      </LoadingButton>
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        onClose={(e: any) => {
          e.stopPropagation()
          setOpen(false)
        }}
      >
        {CONTACT_STATUS_SELECT_LIST.map((item) => (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()

              onChange(item.value, item.label)
            }}
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </ButtonGroup>
  )
}
