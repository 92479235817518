import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Campaign } from 'src/interfaces/campaigns'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { CampaignsTableHeader } from './CampaignsTableHeader'
import { CampaignStatus, CampaignStatusMap } from 'src/constants/campaigns'

interface Props {
  campaigns: Campaign[]
  disablePagination?: boolean
  page: number
  rowsPerPage: number
  total: number
  setPage: (page: number) => void
  setRowsPerPage: (rowsPerPage: number) => void
  onClick: (campaignId: string) => void
}

export const CampaignsTable = ({
  campaigns,
  disablePagination,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  onClick,
  total,
}: Props) => {
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, borderRadius: '8px', overflow: 'hidden' }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <CampaignsTableHeader />
            <TableBody>
              {campaigns.map((row) => {
                return (
                  <TableRow hover onClick={() => onClick(row.id)} tabIndex={-1} key={row.id}>
                    <TableCell align="left">
                      <Typography>{row.name}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>{row.notContacted}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>{row.contacted}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>{row.locationDisplayName}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <div className="flex items-start">
                        <div
                          style={{
                            backgroundColor:
                              row.status === CampaignStatus.Active
                                ? colors.BRAND_BLUE
                                : colors.GRAY_DARK_COOL,
                            padding: '8px',
                            borderRadius: '4px',
                          }}
                        >
                          <Typography color="white" variant="body2" fontWeight={500}>
                            {CampaignStatusMap[row.status]}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!disablePagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  )
}
