import { Typography, Button, TextField } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { getUser } from 'src/selectors/auth'
import { AccountChangePasswordModal } from './account-change-password-modal-form/AccountChangePasswordModalForm'

export const AccountCredentials = () => {
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false)

  const user = useSelector(getUser)

  return (
    <div>
      <div className="flex justify-between items-center mb-4 mt-9">
        <Typography variant="subtitle1">Account Credentials</Typography>
        <Button
          onClick={() => {
            setChangePasswordModalOpen(true)
          }}
        >
          Update Credentials
        </Button>
      </div>
      <div className="flex justify-between mt-9">
        <TextField className="w-80 mr-8" value={user?.email} disabled label="Your email address" />
        <TextField className="w-80" value="***************" disabled label="Password" />
      </div>
      <AccountChangePasswordModal
        isOpen={changePasswordModalOpen}
        onClose={() => {
          setChangePasswordModalOpen(false)
        }}
      />
    </div>
  )
}
