import React from 'react'
import { Box, Modal, Button, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

interface Props {
  isOpen: boolean
  isLoading?: boolean
  error: string | null
  onSubmit(): void
  onClose(): void
}

const StopCampaignModalComponent = ({ isOpen, isLoading, error, onSubmit, onClose }: Props) => {
  return (
    <Modal
      container={() => document.getElementById('root')}
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '780px',
          bgcolor: 'background.paper',
          p: '50px 150px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h2">Are you sure you want to stop this campaign?</Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
          Stopping this campaign will remove it from the Para app and users will no longer be able
          to respond and interact with your posting.{' '}
        </Typography>
        {error ? (
          <div className="mb-4 text-center">
            <Typography variant="body1" color="error.main">
              {error}
            </Typography>
          </div>
        ) : null}
        <Box>
          <LoadingButton
            sx={{ mr: 2, width: '230px' }}
            color="secondary"
            variant="outlined"
            loading={isLoading}
            onClick={onSubmit}
          >
            Stop Campaign
          </LoadingButton>
          <Button sx={{ width: '230px' }} color="primary" variant="contained" onClick={onClose}>
            Continue Campaign
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export const StopCampaignModal = React.memo(StopCampaignModalComponent)
