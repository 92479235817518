import styled from '@emotion/styled'
import { TextField, Typography } from '@mui/material'
import { AuthConditionWrapper } from './AuthConditionWrapper'

export const PublicFooter = () => {
  return (
    <Root>
      <InnerContainer className="flex justify-between">
        <LeftContainer>
          <LogoContainer>
            <Logo src={require('../../assets/images/public-header-logo.png')} />
          </LogoContainer>
          <AuthConditionWrapper state="public">
            <Typography className="mt-4" variant="h5" fontWeight={600}>
              Hire experienced delivery drivers, in your city, in days not weeks.
            </Typography>
          </AuthConditionWrapper>
        </LeftContainer>
        <RightContainer>
          <AuthConditionWrapper state="public">
            <Typography variant="subtitle1" fontWeight={600}>
              Stay Up to Date
            </Typography>
            <TextField className="w-full mb-4 mt-4" variant="outlined" label="Email Address" />
            <Typography variant="body1">
              Join our mailing list to stay up to date on the latest updates to our partner portal.
            </Typography>
          </AuthConditionWrapper>
        </RightContainer>
      </InnerContainer>
    </Root>
  )
}

const Root = styled.div`
  background: #ffffff;
  border-top: 1px solid #e0e0e0;
  padding: 32px 0 60px;
`

const InnerContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`

const LeftContainer = styled.div`
  width: 310px;
`

const LogoContainer = styled.div`
  font-size: 0;
`

const Logo = styled.img`
  width: 230px;
  height: 48px;
`

const RightContainer = styled.div`
  width: 330px;
`
