import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  FetchCampaigns,
  CreateCampaign,
  FetchCampaign,
  FetchLocations,
  FetchCampaignsOverview,
  StartCampaign,
  StopCampaign,
  UpdateCampaign,
} from 'src/constants/actionTypes'
import { ApiService, callSecureApi } from './api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { Campaign } from 'src/interfaces/campaigns'

interface FetchCampaignsRequest {
  limit?: number
  offset?: number
  search?: string
  status?: string
}

function* onFetchCampaigns(action: AsyncAction<FetchCampaignsRequest>) {
  try {
    const { limit = 5, offset = 0, search = '', status = '' } = action.payload || {}

    const { payload }: { payload: { data: Campaign[]; total: number } } = yield call(
      callSecureApi,
      Endpoints.FetchCampaigns,
      {
        apiService: ApiService.Paraworks,
        query: {
          limit,
          offset,
          search,
          status,
        },
      },
    )

    yield put(FetchCampaigns.success({ campaigns: payload.data, total: payload.total }))

    if (action.next) {
      action.next(null, payload as any)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(FetchCampaigns.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface FetchCampaignRequest {
  id: string
}

function* onFetchCampaign(action: AsyncAction<FetchCampaignRequest>) {
  const { id } = action.payload

  try {
    const { payload }: { payload: Campaign } = yield call(callSecureApi, Endpoints.FetchCampaign, {
      apiService: ApiService.Paraworks,
      params: [
        {
          field: ':id',
          value: id,
        },
      ],
    })

    yield put(FetchCampaign.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(FetchCampaign.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onCreateCampaign(action: AsyncAction<Campaign>) {
  try {
    const campaign = action.payload

    yield call(callSecureApi, Endpoints.CreateCampaign, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      body: campaign,
    })

    yield put(CreateCampaign.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(CreateCampaign.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onUpdateCampaign(action: AsyncAction<Campaign>) {
  try {
    const campaign = action.payload

    yield call(callSecureApi, Endpoints.UpdateCampaign, {
      apiService: ApiService.Paraworks,
      method: 'PUT',
      body: campaign,
      params: [
        {
          field: ':id',
          value: campaign.id,
        },
      ],
    })

    yield put(UpdateCampaign.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(UpdateCampaign.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onFetchLocations(action: AsyncAction<void>) {
  try {
    const { payload }: { payload: Location[] } = yield call(
      callSecureApi,
      Endpoints.FetchLocations,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(FetchLocations.success(payload))

    if (action.next) {
      action.next(null, payload as any)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(FetchLocations.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface FetchCampaignsOverviewPayload {
  activeCampaigns: number
  newApplicants: number
  contactedApplicants: number
}

function* onFetchCampaignsOverview() {
  try {
    const { payload }: { payload: FetchCampaignsOverviewPayload } = yield call(
      callSecureApi,
      Endpoints.FetchCampaignsOverview,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(FetchCampaignsOverview.success(payload))
  } catch (err: any) {
    yield put(FetchCampaignsOverview.failed(err))
  }
}

interface StartCampaignRequest {
  id: string
}

function* onStartCampaign(action: AsyncAction<StartCampaignRequest>) {
  const { id } = action.payload

  try {
    yield call(callSecureApi, Endpoints.StartCampaign, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      params: [
        {
          field: ':id',
          value: id,
        },
      ],
    })

    yield put(StartCampaign.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(StartCampaign.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface StopCampaignRequest {
  id: string
}

function* onStopCampaign(action: AsyncAction<StopCampaignRequest>) {
  const { id } = action.payload

  try {
    yield call(callSecureApi, Endpoints.StopCampaign, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      params: [
        {
          field: ':id',
          value: id,
        },
      ],
    })

    yield put(StopCampaign.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(StopCampaign.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* campaignsSaga() {
  yield takeEvery(FetchLocations.type.REQUEST, onFetchLocations)
  yield takeEvery(FetchCampaigns.type.REQUEST, onFetchCampaigns)
  yield takeEvery(FetchCampaign.type.REQUEST, onFetchCampaign)
  yield takeEvery(CreateCampaign.type.REQUEST, onCreateCampaign)
  yield takeEvery(UpdateCampaign.type.REQUEST, onUpdateCampaign)
  yield takeEvery(FetchCampaignsOverview.type.REQUEST, onFetchCampaignsOverview)
  yield takeEvery(StartCampaign.type.REQUEST, onStartCampaign)
  yield takeEvery(StopCampaign.type.REQUEST, onStopCampaign)
}
