import { Routes, Route, Navigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { CampaignsPage } from 'src/pages/campaigns/CampaignsPage'
import { BusinessSizeOnboardingPage } from 'src/pages/onboarding/BusinessSizeOnboardingPage'
import { HaveOnfleetOnboardingPage } from 'src/pages/onboarding/HaveOnfleetOnboardingPage'
import { EnterOnfleetOnboardingPage } from 'src/pages/onboarding/EnterOnfleetOnboardingPage'
import { UploadLogoOnboardingPage } from 'src/pages/onboarding/UploadLogoOnboardingPage'
import { FirstCreateCampaignPage } from 'src/pages/campaigns/FirstCreateCampaignPage'
import { SecondCreateCampaignPage } from 'src/pages/campaigns/SecondCreateCampaignPage'
import { OverviewPage } from 'src/pages/overview/OverviewPage'
import { SecureHooksContainer } from 'src/SecureHooksContainer'
import { memo } from 'react'
import { CampaignPage } from 'src/pages/campaign/CampaignPage'
import { UpdateCampaignPage } from 'src/pages/campaigns/UpdateCampaignPage'
import { ApplicantPage } from 'src/pages/applicant/ApplicantPage'
import { AccountPage } from 'src/pages/account/AccountPage'

export const SecureRoutes = memo(() => {
  return (
    <>
      <Routes>
        {/* onboarding */}
        <Route
          path={ROUTES.BUSINESS_SIZE_ONBOARDING_URL}
          element={<BusinessSizeOnboardingPage />}
        />
        <Route
          path={ROUTES.HAVE_ONFLEET_KEY_ONBOARDING_URL}
          element={<HaveOnfleetOnboardingPage />}
        />
        <Route
          path={ROUTES.ENTER_ONFLEET_KEY_ONBOARDING_URL}
          element={<EnterOnfleetOnboardingPage />}
        />
        <Route path={ROUTES.UPLOAD_LOGO_ONBOARDING_URL} element={<UploadLogoOnboardingPage />} />
        {/* ----------- */}

        <Route path={ROUTES.OVERVIEW_URL} element={<OverviewPage />} />

        <Route path={ROUTES.FIRST_CREATE_CAMPAIGN_URL} element={<FirstCreateCampaignPage />} />
        <Route path={ROUTES.SECOND_CREATE_CAMPAIGN_URL} element={<SecondCreateCampaignPage />} />
        <Route path={ROUTES.CAMPAIGNS_URL} element={<CampaignsPage />} />
        <Route path={ROUTES.CAMPAIGN_URL} element={<CampaignPage />} />
        <Route path={ROUTES.UPDATE_CAMPAIGN_URL} element={<UpdateCampaignPage />} />

        <Route path={ROUTES.APPLICANT_URL} element={<ApplicantPage />} />

        <Route path={ROUTES.ACCOUNT_URL} element={<AccountPage />} />
        <Route path="*" element={<Navigate to={ROUTES.OVERVIEW_URL} />} />
      </Routes>
      <SecureHooksContainer />
    </>
  )
})
