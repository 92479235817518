import TextField from '@mui/material/TextField'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { SetOnboardingData } from 'src/constants/actionTypes'
import { BusinessSizeFormValues } from './BusinessSizeForm.interface'
import { validateForm } from './BusinessSizeForm.validator'
import { BasicSelect } from 'src/components/shared/form/BasicSelect'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { ContinueButton } from 'src/components/shared/ContinueButton'
import { getOnboardingData } from 'src/selectors/onboarding'
import { convertStringsToSelectObjects } from 'src/utils/fields'
import { getCompany } from 'src/selectors/company'
import { useEffect } from 'react'
import { DISPATCH_TOOLS, DRIVER_FLEET_SIZE, WEEKLY_DELIVERY_VOLUME } from 'src/constants/onboarding'

export const BusinessSizeForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onboardingData = useSelector(getOnboardingData)

  const formValidator = (values: BusinessSizeFormValues) => validateForm(values, values)

  const handleFormSubmit = async (values: BusinessSizeFormValues) => {
    dispatch(SetOnboardingData.success(values))

    navigate(ROUTES.HAVE_ONFLEET_KEY_ONBOARDING_URL)
  }

  const company = useSelector(getCompany)
  useEffect(() => {
    if (company) {
      navigate(ROUTES.OVERVIEW_URL)
    }
  }, [company])

  return (
    <Formik
      initialValues={{
        ...onboardingData,
      }}
      onSubmit={handleFormSubmit}
      validate={formValidator}
    >
      {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
        <div className="mt-8">
          <TextField
            variant="outlined"
            className="w-1/2 mb-4"
            onChange={handleChange('name')}
            onBlur={handleBlur('name')}
            value={values.name}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            label="Company Name"
          />
          <div className="w-full mb-4">
            <BasicSelect
              className="w-1/4 pr-4"
              onChange={handleChange('driverFleet')}
              onBlur={handleBlur('driverFleet')}
              value={values.driverFleet}
              error={Boolean(touched.driverFleet && errors.driverFleet)}
              helperText={touched.driverFleet && errors.driverFleet}
              label="Current driver fleet size"
              options={convertStringsToSelectObjects(DRIVER_FLEET_SIZE)}
            />
            <BasicSelect
              className="w-1/4 pr-4"
              onChange={handleChange('weeklyDeliveryVolume')}
              onBlur={handleBlur('weeklyDeliveryVolume')}
              value={values.weeklyDeliveryVolume}
              error={Boolean(touched.weeklyDeliveryVolume && errors.weeklyDeliveryVolume)}
              helperText={touched.weeklyDeliveryVolume && errors.weeklyDeliveryVolume}
              label="Weekly Deliver Volume"
              options={convertStringsToSelectObjects(WEEKLY_DELIVERY_VOLUME)}
            />

            <BasicSelect
              className="w-1/4 mb-4"
              onChange={handleChange('dispatchTools')}
              onBlur={handleBlur('dispatchTools')}
              value={values.dispatchTools}
              error={Boolean(touched.dispatchTools && errors.dispatchTools)}
              helperText={touched.dispatchTools && errors.dispatchTools}
              label="Current dispatch tools"
              options={convertStringsToSelectObjects(DISPATCH_TOOLS)}
            />
          </div>
          <ContinueButton
            onClick={() => {
              handleSubmit()
            }}
          />
        </div>
      )}
    </Formik>
  )
}
