import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { FetchUser, LoginEmailPassword, SetUserToken } from 'src/constants/actionTypes'
import { ApiService, callApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'

interface LoginEmailPasswordRequest {
  email: string
  password: string
}

interface LoginEmailPasswordPayload {
  accessToken: string
}

function* onLoginEmailPassword(action: AsyncAction<LoginEmailPasswordRequest>) {
  const { email, password } = action.payload

  try {
    const { payload }: { payload: LoginEmailPasswordPayload } = yield call(
      callApi,
      Endpoints.LoginEmailPassword,
      {
        method: 'POST',
        body: {
          email,
          password,
        },
        apiService: ApiService.Paraworks,
      },
    )

    yield put(LoginEmailPassword.success())
    yield put(SetUserToken.request(payload.accessToken))
    yield put(FetchUser.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(LoginEmailPassword.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* loginSaga() {
  yield takeEvery(LoginEmailPassword.type.REQUEST, onLoginEmailPassword)
}
