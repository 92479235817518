import { call, put, takeEvery } from 'redux-saga/effects'
import { Action, AsyncAction } from 'src/utils/reduxUtils'
import {
  FetchCompany,
  FetchUser,
  Logout,
  SetOnboardingData,
  SetUser,
  SetUserToken,
  UpdatePassword,
} from 'src/constants/actionTypes'
import { ApiService, callSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { User } from 'src/interfaces/user'
import { UserService } from 'src/services/UserService'

function* onFetchUser(action: AsyncAction<void>) {
  try {
    const { payload }: { payload: User } = yield call(callSecureApi, Endpoints.FetchUser, {
      apiService: ApiService.Paraworks,
    })

    yield put(FetchUser.success())
    yield put(SetUser.request(payload))

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(FetchUser.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onSetUser(action: Action<User>) {
  const user = action.payload

  yield put(SetUser.success(user))

  UserService.setStoredUser(user)
}

function* onSetUserToken(action: Action<string>) {
  const userToken = action.payload

  yield put(SetUserToken.success(userToken))

  UserService.setStoredUserToken(userToken)
}

function* onUpdatePassword(action: AsyncAction<{ oldPassword: string; newPassword: string }>) {
  try {
    const { oldPassword, newPassword } = action.payload

    const { payload }: { payload: { accessToken: string } } = yield call(
      callSecureApi,
      Endpoints.UpdatePassword,
      {
        apiService: ApiService.Paraworks,
        method: 'POST',
        body: {
          oldPassword,
          newPassword,
        },
      },
    )

    yield put(UpdatePassword.success())
    yield put(SetUserToken.request(payload.accessToken))

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(FetchUser.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onLogout() {
  yield put(SetUser.request(null))
  yield put(SetUserToken.request(null))
  yield put(FetchCompany.success(null))
  yield put(SetOnboardingData.request(null))
  yield put(Logout.success())
}

export function* userSaga() {
  yield takeEvery(FetchUser.type.REQUEST, onFetchUser)
  yield takeEvery(UpdatePassword.type.REQUEST, onUpdatePassword)
  yield takeEvery(SetUser.type.REQUEST, onSetUser)
  yield takeEvery(SetUserToken.type.REQUEST, onSetUserToken)
  yield takeEvery(Logout.type.REQUEST, onLogout)
}
