import { SetOnboardingData, Logout } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { OnboardingState } from './types'

const getInitialState = (): OnboardingState => ({
  name: undefined,
  driverFleet: undefined,
  weeklyDeliveryVolume: undefined,
  dispatchTools: undefined,
  haveOnfleet: undefined,
  onfleetApiKey: undefined,
  logo: undefined,
})

export const onboardingReducer = (
  state: OnboardingState = getInitialState(),
  action: Action<any>,
): OnboardingState => {
  switch (action.type) {
    case SetOnboardingData.type.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
