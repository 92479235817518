import { Typography } from '@mui/material'
import { colors } from 'src/theme'

interface AccountSummaryItemProps {
  count: number
  title: string
}

const AccountSummaryItem = ({ count, title }: AccountSummaryItemProps) => (
  <div
    className="h-24 flex flex-1 justify-center items-center"
    style={{
      border: `1px solid #DAD9E0`,
      borderRadius: '8px',
      backgroundColor: colors.GRAY_PALE_COOL,
    }}
  >
    <Typography className="mr-4" variant="h2" color={colors.GRAY_DARK_COOL}>
      {count}
    </Typography>
    <Typography variant="subtitle1">{title}</Typography>
  </div>
)

interface AccountSummaryProps {
  campaignsCount?: number
  newApplicantsCount?: number
  contactedApplicants?: number
}

export const AccountSummary = ({
  campaignsCount,
  newApplicantsCount,
  contactedApplicants,
}: AccountSummaryProps) => {
  return (
    <div
      className="w-full p-6 pt-4"
      style={{
        backgroundColor: 'white',
        boxShadow: `0px 4px 16px rgba(0, 0, 0, 0.07)`,
        borderRadius: '4px',
      }}
    >
      <Typography fontWeight={600} color={colors.GRAY_DARK_COOL}>
        Account Summary
      </Typography>
      <div className="flex space-x-6 mt-4">
        <AccountSummaryItem count={campaignsCount || 0} title="Active Campaigns" />
        <AccountSummaryItem count={newApplicantsCount || 0} title="New Applicants" />
        <AccountSummaryItem count={contactedApplicants || 0} title="Applicants Contacted" />
      </div>
    </div>
  )
}
