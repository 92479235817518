import { Button, Link, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { colors } from 'src/theme'

export const CreateCampaignCard = () => {
  const navigate = useNavigate()

  const [apiKey, setApiKey] = useState('')

  return (
    <div
      className="px-6 py-8 flex-1 mr-8 max-w-lg"
      style={{
        backgroundColor: 'white',
        boxShadow: `0px 4px 16px rgba(0, 0, 0, 0.07)`,
        borderRadius: '4px',
      }}
    >
      <Typography variant="h4">Get started with your first recruitment campaign.</Typography>
      <Typography fontWeight={500} className="mt-2">
        Create your first recruitment campaign and start your search today.
      </Typography>
      <Button
        onClick={() => navigate(ROUTES.FIRST_CREATE_CAMPAIGN_URL)}
        variant="contained"
        className="mt-16 w-60"
      >
        Create New Campaign
      </Button>
    </div>
  )
}
