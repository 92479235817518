import { Button, ButtonProps } from '@mui/material'
import { memo } from 'react'

const SkipButtonComponent = (props: ButtonProps) => {
  return (
    <Button variant="outlined" color="secondary" className="w-24" {...props}>
      Skip
    </Button>
  )
}

export const SkipButton = memo(SkipButtonComponent)
