import {
  arrayValidator,
  composeValidators,
  createValidator,
  stateValidator,
} from 'src/utils/validation/core'
import { isRequired, maxLength } from 'src/utils/validation/validator'

export const validateForm = stateValidator({
  name: composeValidators([createValidator(isRequired, 'Required')]),
  cardTitle: composeValidators([
    createValidator(isRequired, 'Required'),
    createValidator((value) => maxLength(value, 40), 'Max length 40'),
  ]),
  cardContent: composeValidators([createValidator(isRequired, 'Required')]),
  pageTitle: composeValidators([createValidator(isRequired, 'Required')]),
  pageContent: composeValidators([createValidator(isRequired, 'Required')]),
  price: composeValidators([createValidator(isRequired, 'Required')]),
  priceUnit: composeValidators([createValidator(isRequired, 'Required')]),
  requirements: arrayValidator(createValidator(isRequired, 'Required')),
})
