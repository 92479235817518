const BASE_URL = '/'

const LOGIN_URL = `${BASE_URL}login`
const REGISTER_URL = `${BASE_URL}sign-up`
const FORGOT_PASSWORD_URL = `${BASE_URL}forgot-password`
const FORGOT_PASSWORD_CONFIRM_URL = `${BASE_URL}forgot-password-confirm`

const CONFIRM_WORKS_EMAIL = `${BASE_URL}/works-confirm-email`
const CONFIRM_HOME_PROFILE_EMAIL = `${BASE_URL}/profile-confirm-email`
const CONFIRM_PLATFORM_EMAIL = `${BASE_URL}/platform-confirm-email`

const ONBOARDING_URL = `${BASE_URL}onboarding`
const BUSINESS_SIZE_ONBOARDING_URL = `${ONBOARDING_URL}/business-size`
const HAVE_ONFLEET_KEY_ONBOARDING_URL = `${ONBOARDING_URL}/have-onfleet-key`
const ENTER_ONFLEET_KEY_ONBOARDING_URL = `${ONBOARDING_URL}/enter-onfleet-key`
const UPLOAD_LOGO_ONBOARDING_URL = `${ONBOARDING_URL}/upload-logo`

const CAMPAIGNS_URL = `${BASE_URL}campaigns`
const CAMPAIGN_URL = `${CAMPAIGNS_URL}/:id`
const UPDATE_CAMPAIGN_URL = `${CAMPAIGNS_URL}/:id/update`
const CAMPAIGN_CREATE_URL = `${CAMPAIGNS_URL}/create`
const FIRST_CREATE_CAMPAIGN_URL = `${CAMPAIGN_CREATE_URL}/where-do-you-need-drivers`
const SECOND_CREATE_CAMPAIGN_URL = `${CAMPAIGN_CREATE_URL}/job-details`

const APPLICANTS_URL = `${BASE_URL}applicants`
const APPLICANT_URL = `${APPLICANTS_URL}/:id`

const ACCOUNT_URL = `${BASE_URL}account`

const OVERVIEW_URL = `${BASE_URL}overview`

export const ROUTES = {
  BASE_URL,
  LOGIN_URL,
  REGISTER_URL,
  FORGOT_PASSWORD_URL,
  FORGOT_PASSWORD_CONFIRM_URL,

  CONFIRM_WORKS_EMAIL,
  CONFIRM_HOME_PROFILE_EMAIL,
  CONFIRM_PLATFORM_EMAIL,

  BUSINESS_SIZE_ONBOARDING_URL,
  HAVE_ONFLEET_KEY_ONBOARDING_URL,
  ENTER_ONFLEET_KEY_ONBOARDING_URL,
  UPLOAD_LOGO_ONBOARDING_URL,

  CAMPAIGN_CREATE_URL,
  FIRST_CREATE_CAMPAIGN_URL,
  SECOND_CREATE_CAMPAIGN_URL,

  CAMPAIGNS_URL,
  CAMPAIGN_URL,
  UPDATE_CAMPAIGN_URL,
  OVERVIEW_URL,

  APPLICANT_URL,

  ACCOUNT_URL,
}
