export enum HaveOnfleetAnswers {
  YES = 'Answers::YES',
  NO = 'Answers::NO',
}

export interface OnboardingState {
  name?: string
  driverFleet?: string
  weeklyDeliveryVolume?: string
  dispatchTools?: string
  haveOnfleet?: HaveOnfleetAnswers
  onfleetApiKey?: string
  logo?: string
}
