import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { ReactNode } from 'react'
import { Progress } from '../shared/Progress'

interface Props {
  children: ReactNode
  screenNumber: number
  title: string
  subtitle?: ReactNode
}

export const OnboardingContainer = ({ children, screenNumber, title, subtitle }: Props) => {
  return (
    <Root className="pt-12 pb-12">
      <Progress amount={4} current={screenNumber} />
      <Typography className="w-1/2 mt-8" variant="h1">
        {title}
      </Typography>
      {subtitle && (
        <Typography className="my-6" display="flex" variant="body1">
          {subtitle}
        </Typography>
      )}
      {children}
    </Root>
  )
}

const Root = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`
