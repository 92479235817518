import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SetOnboardingData } from 'src/constants/actionTypes'
import { ROUTES } from 'src/constants/routes'
import { HaveOnfleetAnswers } from 'src/reducers/onboarding/types'
import { getCompany } from 'src/selectors/company'
import { getOnboardingData } from 'src/selectors/onboarding'
import { BackButton } from '../../shared/BackButton'
import { ContinueButton } from '../../shared/ContinueButton'
import { CreateOnfleetAccountModal } from './CreateOnfleetAccountModal'

export const HaveOnfleetContent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onboardingData = useSelector(getOnboardingData)
  const company = useSelector(getCompany)

  const [answer, setAnswer] = useState<HaveOnfleetAnswers | undefined>(onboardingData.haveOnfleet)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const onContinue = () => {
    if (answer === HaveOnfleetAnswers.NO) {
      setOpenModal(true)
    } else {
      dispatch(SetOnboardingData.success({ haveOnfleet: answer }))

      navigate(ROUTES.ENTER_ONFLEET_KEY_ONBOARDING_URL)
    }
  }

  const onCompleteLater = () => {
    setOpenModal(false)

    dispatch(SetOnboardingData.success({ haveOnfleet: HaveOnfleetAnswers.NO }))

    navigate(ROUTES.UPLOAD_LOGO_ONBOARDING_URL)
  }

  const onSignUp = () => {
    const signUpTab = window.open(process.env.REACT_APP_ONFLEET_SIGN_UP_URL, '_blank')

    if (signUpTab) {
      signUpTab.focus()
    }

    setOpenModal(false)

    dispatch(SetOnboardingData.success({ haveOnfleet: HaveOnfleetAnswers.YES }))

    navigate(ROUTES.ENTER_ONFLEET_KEY_ONBOARDING_URL)
  }

  useEffect(() => {
    if (company) {
      navigate(ROUTES.OVERVIEW_URL)
    }
  }, [company])

  return (
    <div>
      <div className="mb-8">
        <Button
          onClick={() => {
            setAnswer(HaveOnfleetAnswers.YES)
          }}
          variant="outlined"
          className="mr-6 w-60"
          color={answer === HaveOnfleetAnswers.YES ? 'primary' : 'secondary'}
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            setAnswer(HaveOnfleetAnswers.NO)
          }}
          variant="outlined"
          className="w-60"
          color={answer === HaveOnfleetAnswers.NO ? 'primary' : 'secondary'}
        >
          No
        </Button>
      </div>
      <BackButton />
      <ContinueButton disabled={!answer} onClick={onContinue} />

      <CreateOnfleetAccountModal
        open={openModal}
        onCompleteLater={onCompleteLater}
        onSignUp={onSignUp}
      />
    </div>
  )
}
