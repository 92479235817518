import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

interface Props {
  title?: string
  copy?: string
  logoUrl?: string
}

export const BannerPreview = ({ title, copy, logoUrl }: Props) => {
  return (
    <div className="relative mr-8">
      <img
        style={{ width: 246, height: 512 }}
        src={require('../../../assets/images/bannerPreview.png')}
      />
      <div
        style={{
          backgroundColor: 'white',
          width: 220,
          height: 90,
          position: 'absolute',
          top: 80,
          left: 13,
          padding: 8,
          display: 'flex',
        }}
      >
        <img style={{ width: 36, height: 36, marginRight: 8 }} src={logoUrl} />
        <div className="flex flex-1 flex-col">
          <Title className="mb-1">{title || 'Banner Title'}</Title>
          <Subtitle>{copy || 'Banner content describing your opportunity goes here.'}</Subtitle>
        </div>
      </div>
    </div>
  )
}

const Title = styled(Typography)`
  width: 160px;
  height: 12px;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Subtitle = styled(Typography)`
  width: 160px;
  height: 48px;
  font-size: 10px;
  line-height: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
`
