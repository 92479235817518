import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { FetchUser, RegisterEmailPassword, SetUserToken } from 'src/constants/actionTypes'
import { ApiService, callApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'

interface RegisterEmailPasswordRequest {
  firstName: string
  lastName: string
  email: string
  password: string
}

interface RegisterEmailPasswordPayload {
  accessToken: string
}

function* onRegisterEmailPassword(action: AsyncAction<RegisterEmailPasswordRequest>) {
  const { firstName, lastName, email, password } = action.payload

  try {
    const { payload }: { payload: RegisterEmailPasswordPayload } = yield call(
      callApi,
      Endpoints.RegisterEmailPassword,
      {
        method: 'POST',
        body: {
          firstName,
          lastName,
          email,
          password,
          phone: '123',
        },
        apiService: ApiService.Paraworks,
      },
    )

    yield put(RegisterEmailPassword.success())
    yield put(SetUserToken.request(payload.accessToken))
    yield put(FetchUser.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(RegisterEmailPassword.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* registerSaga() {
  yield takeEvery(RegisterEmailPassword.type.REQUEST, onRegisterEmailPassword)
}
