import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchUser } from 'src/constants/actionTypes'
import { getUserToken } from 'src/selectors/auth'

export const initializeApplication = () => {
  const dispatch = useDispatch()

  const userToken = useSelector(getUserToken)

  useEffect(() => {
    if (!userToken) {
      return
    }

    dispatch(FetchUser.request())
  }, [])
}
