import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ConfirmPlatformEmail } from 'src/constants/actionTypes'
import { colors } from 'src/theme'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { BrandHeader } from 'src/components/layout/BrandHeader'
import qs from 'query-string'

export const ConfirmPlatformEmailPage = () => {
  const dispatch = useDispatch()

  const location = useLocation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const parsedQuery = useMemo(() => qs.parse(location.search, { decode: false }), [location])

  const handleConfirmPlatformEmail = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        ConfirmPlatformEmail.request({
          code: parsedQuery.code,
          platform: parsedQuery.platform,
          userId: parsedQuery.userId,
        }),
      )
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }, [parsedQuery])

  useEffect(() => {
    handleConfirmPlatformEmail()
  }, [])

  return (
    <div className="h-full flex flex-col">
      <div className="flex-1">
        <BrandHeader />
        <div className="px-4 py-12 max-w-3xl mx-auto">
          {isLoading ? (
            <div className="flex justify-center mt-6">
              <CircularProgress />
            </div>
          ) : (
            <div>
              {!error ? (
                <div className="flex justify-center">
                  <svg
                    className="w-24 h-24 mb-6"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  >
                    <path
                      fill={colors.SUCCESS}
                      d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm7 7.457l-9.005 9.565-4.995-5.865.761-.649 4.271 5.016 8.24-8.752.728.685z"
                    />
                  </svg>
                </div>
              ) : null}
              <Typography variant="h4" fontWeight={600} textAlign="center">
                {error ? 'Email address confirm failed' : 'Email address confirmed'}
              </Typography>
              <Typography
                className="mt-4"
                variant="h6"
                color={error ? colors.ERROR : undefined}
                textAlign="center"
              >
                {error ||
                  'You have successfully confirmed your email address. Please go back to the Para app.'}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
