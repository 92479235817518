import { Campaign, CampaignLocation, CampaignsOverview } from 'src/interfaces/campaigns'
import { CampaignsList } from 'src/reducers/campaigns/types'
import { State } from '../reducers/types'

export const getCampaigns = (state: State): CampaignsList => state.campaigns.list

export const getLocations = (state: State): CampaignLocation[] => state.campaigns.locations

export const getCampaignsOverview = (state: State): CampaignsOverview | null =>
  state.campaigns.campaignsOverview

export const getSelectedCampaign = (state: State): Campaign | null =>
  state.campaigns.selectedCampaign
