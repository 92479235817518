import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RegisterEmailPassword } from 'src/constants/actionTypes'
import { ROUTES } from 'src/constants/routes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { RegisterFormValues } from './RegisterForm.interface'
import { validateForm } from './RegisterForm.validator'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'

const initialValues: RegisterFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
}

export const RegisterForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const formValidator = (values: RegisterFormValues) => validateForm(values, values)

  const handleFormSubmit = async (values: RegisterFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(dispatch, RegisterEmailPassword.request(values))
    } catch (err: any) {
      const errorMessage =
        typeof err === 'string' ? err : err?.message ? err?.message : 'Failed to login'

      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const handleLoginNavigate = () => {
    navigate(ROUTES.LOGIN_URL)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormSubmit} validate={formValidator}>
      {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
        <div>
          <TextField
            variant="outlined"
            className="w-full mb-4"
            onChange={handleChange('firstName')}
            onBlur={handleBlur('firstName')}
            value={values.firstName}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            label="First Name"
          />
          <TextField
            variant="outlined"
            className="w-full mb-4"
            onChange={handleChange('lastName')}
            onBlur={handleBlur('lastName')}
            value={values.lastName}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
            label="Last Name"
          />
          <TextField
            variant="outlined"
            className="w-full mb-4"
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            label="Email Address"
          />
          <TextField
            variant="outlined"
            className="w-full mb-4"
            onChange={handleChange('password')}
            onBlur={handleBlur('password')}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            type="password"
            label="Password"
          />
          <TextField
            variant="outlined"
            className="w-full mb-4"
            onChange={handleChange('confirmPassword')}
            onBlur={handleBlur('confirmPassword')}
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            type="password"
            label="Confirm Password"
          />
          {error ? (
            <div className="mb-4 text-center">
              <Typography variant="subtitle1" color="error.main">
                {error}
              </Typography>
            </div>
          ) : null}
          <LoadingButton
            className="w-full mt-4"
            variant="contained"
            size="large"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            Sign Up
          </LoadingButton>
          <Button
            variant="outlined"
            className="w-full mt-4"
            size="large"
            onClick={handleLoginNavigate}
          >
            Have an Account? Log In
          </Button>
        </div>
      )}
    </Formik>
  )
}
