import Typography from '@mui/material/Typography'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { SetCampaignCreateData } from 'src/constants/actionTypes'
import { CampaignCreateLocationFormValues } from './CampaignCreateLocationForm.interface'
import { validateForm } from './CampaignCreateLocationForm.validator'
import LoadingButton from '@mui/lab/LoadingButton'
import { BasicSelect } from 'src/components/shared/form/BasicSelect'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { getLocations } from 'src/selectors/campaigns'
import { CampaignCreateState } from 'src/reducers/campaign-create/types'

interface Props {
  campaignData: CampaignCreateState
}

export const CampaignCreateLocationForm = ({ campaignData }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const locations = useSelector(getLocations)

  const formValidator = (values: CampaignCreateLocationFormValues) => validateForm(values, values)

  const handleFormSubmit = async (values: CampaignCreateLocationFormValues) => {
    dispatch(SetCampaignCreateData.success(values))

    navigate(ROUTES.SECOND_CREATE_CAMPAIGN_URL)
  }

  return (
    <Formik
      initialValues={{ ...campaignData }}
      onSubmit={handleFormSubmit}
      validate={formValidator}
    >
      {({ values, handleSubmit, handleChange, handleBlur, touched, errors }) => (
        <div className="w-96">
          <Typography className="mt-8 mb-4" variant="subtitle1">
            Location Details
          </Typography>
          <BasicSelect
            className="w-full pr-4"
            onChange={handleChange('locationRecordId')}
            onBlur={handleBlur('locationRecordId')}
            value={values.locationRecordId}
            error={Boolean(touched.locationRecordId && errors.locationRecordId)}
            helperText={touched.locationRecordId && errors.locationRecordId}
            label="Location"
            options={locations
              .map((item) => ({
                label: `${item.city}, ${item.state} - ${item.mileRadius} miles radius`,
                value: item.recordId,
              }))
              .sort((a, b) => (a.label > b.label ? 1 : -1))}
          />
          <LoadingButton className="w-3/4 mt-8" variant="contained" onClick={() => handleSubmit()}>
            Continue
          </LoadingButton>
        </div>
      )}
    </Formik>
  )
}
