import { useSelector } from 'react-redux'
import { OnboardingContainer } from 'src/components/layout/OnboardingContainer'
import { PublicPage } from 'src/components/layout/PublicPage'
import { BusinessSizeForm } from 'src/components/onboarding/business-size-form/BusinessSizeForm'
import { getUser } from 'src/selectors/auth'

export const BusinessSizeOnboardingPage = () => {
  const user = useSelector(getUser)

  return (
    <PublicPage>
      <OnboardingContainer
        title={`Welcome ${user?.firstName} ${user?.lastName}.`}
        subtitle="Let’s complete your partner profile."
        screenNumber={1}
      >
        <BusinessSizeForm />
      </OnboardingContainer>
    </PublicPage>
  )
}
