import { composeValidators, createValidator, stateValidator } from 'src/utils/validation/core'
import { isPasswordValid, isRequired } from 'src/utils/validation/validator'
import validator from 'validator'

const isConfirmPasswordValid = (value: string, context: { password: string }) =>
  value === context.password

export const validateForm = stateValidator({
  firstName: composeValidators([createValidator(isRequired, 'First Name is required')]),
  lastName: composeValidators([createValidator(isRequired, 'Last Name is required')]),
  email: composeValidators([
    createValidator(isRequired, 'Email is required'),
    createValidator(validator.isEmail, 'Email is invalid'),
  ]),
  password: composeValidators([
    createValidator(isRequired, 'Password is required'),
    createValidator(isPasswordValid, 'Password is not valid. Must be 8 or more characters'),
  ]),
  confirmPassword: composeValidators([
    createValidator(isRequired, 'Password confirmation is required'),
    createValidator(isConfirmPasswordValid, 'Passwords should match'),
  ]),
})
