import {
  Logout,
  FetchCampaignsOverview,
  FetchCampaigns,
  FetchLocations,
  FetchCampaign,
} from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { CampaignsState } from './types'

const getInitialState = (): CampaignsState => ({
  list: {
    data: [],
    total: 0,
  },
  locations: [],
  campaignsOverview: null,
  selectedCampaign: null,
})

export const campaignsReducer = (
  state: CampaignsState = getInitialState(),
  action: Action<any>,
): CampaignsState => {
  switch (action.type) {
    case FetchCampaigns.type.SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload.campaigns,
          total: action.payload.total,
        },
      }

    case FetchLocations.type.SUCCESS:
      return {
        ...state,
        locations: action.payload,
      }
    case FetchCampaignsOverview.type.SUCCESS:
      return {
        ...state,
        campaignsOverview: action.payload,
      }
    case FetchCampaign.type.REQUEST:
      return {
        ...state,
        selectedCampaign: null,
      }
    case FetchCampaign.type.SUCCESS:
      return {
        ...state,
        selectedCampaign: action.payload,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
