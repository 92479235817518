import { Box } from '@mui/material'
import { memo } from 'react'
import { colors } from 'src/theme'

interface Props {
  amount: number
  current?: number
}

const ProgressComponent = ({ amount, current }: Props) => (
  <Box display="flex">
    {Array.from(Array(amount)).map((_, index) => (
      <Box
        key={index}
        className="w-32 h-2 mr-2 rounded-xl"
        bgcolor={current && current > index ? colors.BRAND_BLUE : colors.GRAY_MID_COOL}
      />
    ))}
  </Box>
)

export const Progress = memo(ProgressComponent)
