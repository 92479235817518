import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { FetchApplicants, FetchApplicant, UpdateApplicant } from 'src/constants/actionTypes'
import { ApiService, callSecureApi } from './api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { Applicant } from 'src/interfaces/applicants'

interface FetchApplicantsRequest {
  id: string
  limit?: number
  offset?: number
  search?: string
}

interface FetchApplicantsPayload {
  data: Applicant[]
  total: number
}

function* onFetchApplicants(action: AsyncAction<FetchApplicantsRequest>) {
  try {
    const { id, limit = 15, offset = 0, search = '' } = action.payload

    const { payload }: { payload: FetchApplicantsPayload } = yield call(
      callSecureApi,
      Endpoints.FetchApplicants,
      {
        apiService: ApiService.Paraworks,
        query: {
          limit,
          offset,
          search,
        },
        params: [
          {
            field: ':id',
            value: id,
          },
        ],
      },
    )

    yield put(FetchApplicants.success({ data: payload.data, total: payload.total }))

    if (action.next) {
      action.next(null, payload as any)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(FetchApplicants.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onFetchApplicant(action: AsyncAction<{ id: string }>) {
  try {
    const { id } = action.payload

    const { payload }: { payload: FetchApplicantsRequest } = yield call(
      callSecureApi,
      `${Endpoints.FetchApplicant}/${id}`,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(FetchApplicant.success(payload))

    if (action.next) {
      action.next(null, payload as any)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(FetchApplicant.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onUpdateApplicant(action: AsyncAction<Partial<Applicant>>) {
  try {
    const { id, ...body } = action.payload

    if (!id) {
      return
    }

    const { payload }: { payload: any } = yield call(
      callSecureApi,
      Endpoints.UpdateApplicant.replace(':id', id),
      {
        apiService: ApiService.Paraworks,
        method: 'PUT',
        body,
      },
    )

    yield put(UpdateApplicant.success(payload))

    if (action.next) {
      action.next(null, payload as any)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(UpdateApplicant.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* applicantsSaga() {
  yield takeEvery(FetchApplicants.type.REQUEST, onFetchApplicants)
  yield takeEvery(FetchApplicant.type.REQUEST, onFetchApplicant)
  yield takeEvery(UpdateApplicant.type.REQUEST, onUpdateApplicant)
}
