import { Button, ButtonProps } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

const BackButtonComponent = (props: ButtonProps) => {
  const navigate = useNavigate()

  const onBack = useCallback(() => {
    navigate(-1)
  }, [])

  return (
    <Button variant="outlined" color="secondary" className="mr-6 pr-2" onClick={onBack} {...props}>
      <ArrowBackIos />
    </Button>
  )
}

export const BackButton = memo(BackButtonComponent)
