import { Button, TextField } from '@mui/material'
import debounce from 'lodash.debounce'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { CampaignsList } from 'src/reducers/campaigns/types'
import { CampaignsTable } from './CampaignsTable'

interface Props {
  campaigns: CampaignsList
  limit?: number
  disablePagination?: boolean
  disableSearch?: boolean
  onPaginationChange?(limit: number, offset: number, search: string): void
}

export const CampaignsTableContainer = ({
  campaigns,
  limit,
  disablePagination,
  disableSearch,
  onPaginationChange,
}: Props) => {
  const navigate = useNavigate()

  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(limit || 5)
  const [search, setSearch] = useState<string>('')

  const handleClick = (id: string) => {
    navigate(ROUTES.CAMPAIGN_URL.replace(':id', id))
  }

  const handlePageChange = (page: number) => {
    if (onPaginationChange) {
      onPaginationChange(rowsPerPage, page * rowsPerPage, search)
    }

    setPage(page)
  }

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    if (onPaginationChange) {
      onPaginationChange(rowsPerPage, 0, search)
    }

    setRowsPerPage(rowsPerPage)
    setPage(0)
  }

  const handleSetSearch = (value: string) => {
    if (onPaginationChange) {
      onPaginationChange(rowsPerPage, 0, value)
    }

    setSearch(value)
  }

  return (
    <div className="w-full">
      {!disableSearch ? (
        <div className="flex justify-between my-8">
          <TextField
            variant="outlined"
            className="w-1/3"
            onChange={debounce((e: any) => handleSetSearch(e.target.value), 500)}
            label="Search Campaigns"
          />
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(ROUTES.FIRST_CREATE_CAMPAIGN_URL)}
          >
            Create Campaign
          </Button>
        </div>
      ) : null}
      <CampaignsTable
        campaigns={campaigns.data}
        disablePagination={disablePagination}
        page={page}
        rowsPerPage={rowsPerPage}
        total={campaigns.total}
        onClick={handleClick}
        setPage={handlePageChange}
        setRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  )
}
