import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { AuthWelcomeContent } from '../auth/auth-welcome-content/AuthWelcomeContent'

interface Props {
  children: ReactNode
}

export const AuthWelcomeContainer = ({ children }: Props) => {
  return (
    <Root className="pt-12 pb-12">
      <LeftContainer>
        <AuthWelcomeContent />
      </LeftContainer>
      <RightContainer>{children}</RightContainer>
    </Root>
  )
}

const Root = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`

const LeftContainer = styled.div`
  width: 680px;
`

const RightContainer = styled.div`
  width: 330px;
`
