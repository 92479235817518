import { OnboardingContainer } from 'src/components/layout/OnboardingContainer'
import { PublicPage } from 'src/components/layout/PublicPage'
import { UploadLogoContent } from 'src/components/onboarding/UploadLogoContent'

export const UploadLogoOnboardingPage = () => {
  return (
    <PublicPage>
      <OnboardingContainer
        title="Last step, upload your company logo."
        subtitle="This will be used across the Para app whenever your campaigns are shown."
        screenNumber={4}
      >
        <UploadLogoContent />
      </OnboardingContainer>
    </PublicPage>
  )
}
