import { useIsAuthorized } from 'src/hooks/auth'

interface Props {
  state: 'public' | 'secure'
  children: any
}

export const AuthConditionWrapper = ({ state, children }: Props) => {
  const isAuthorized = useIsAuthorized()

  if (state === 'public') {
    return isAuthorized ? null : children
  }

  if (state === 'secure') {
    return isAuthorized ? children : null
  }

  return children
}
