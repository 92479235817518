import { FetchApplicant, FetchApplicants, Logout } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { ApplicantsState } from './types'

const getInitialState = (): ApplicantsState => ({
  list: {
    data: [],
    total: 0,
  },
  selectedApplicant: null,
})

export const applicantsReducer = (
  state: ApplicantsState = getInitialState(),
  action: Action<any>,
): ApplicantsState => {
  switch (action.type) {
    case FetchApplicants.type.SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload.data,
          total: action.payload.total,
        },
      }
    case FetchApplicant.type.REQUEST:
      return {
        ...state,
        selectedApplicant: null,
      }
    case FetchApplicant.type.SUCCESS:
      return {
        ...state,
        selectedApplicant: action.payload,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
