import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { SecurePage } from 'src/components/layout/SecurePage'
import { AccountSummary } from 'src/components/overview/AccountSummary'
import { CreateCampaignCard } from 'src/components/overview/CreateCampaignCard'
import { OnfleetCard } from 'src/components/overview/OnfleetCard'
import {
  StartTrialModal,
  STORAGE_TRIAL_START_SHOWN_KEY,
} from 'src/components/overview/StartTrialModal'
import { ROUTES } from 'src/constants/routes'
import { FetchCampaigns, FetchCampaignsOverview } from 'src/constants/actionTypes'
import { getCampaigns, getCampaignsOverview } from 'src/selectors/campaigns'
import { getCompany } from 'src/selectors/company'
import { CampaignStatus } from 'src/constants/campaigns'
import { CampaignsTableContainer } from 'src/components/campaigns/campaigns-table/CampaignsTableСontainer'

export const OverviewPage = () => {
  const dispatch = useDispatch()

  const company = useSelector(getCompany)
  const campaigns = useSelector(getCampaigns)
  const navigate = useNavigate()

  const [showStartTrialModal, setShowStartTrialModal] = useState(
    !localStorage.getItem(STORAGE_TRIAL_START_SHOWN_KEY),
  )
  const campaignsOverview = useSelector(getCampaignsOverview)

  useEffect(() => {
    dispatch(FetchCampaignsOverview.request())
    dispatch(
      FetchCampaigns.request({
        limit: 5,
        offset: 0,
        status: `${CampaignStatus.Active},${CampaignStatus.Approved},${CampaignStatus.PendingApproval}`,
      }),
    )
  }, [])

  const handleCampaignsPaginationChange = (limit: number, offset: number) => {
    dispatch(
      FetchCampaigns.request({
        limit,
        offset,
        status: `${CampaignStatus.Active},${CampaignStatus.Approved},${CampaignStatus.PendingApproval}`,
      }),
    )
  }

  const showOnfleetCard = !company?.onfleetApiKey

  return (
    <SecurePage>
      <div className="w-full px-8 py-12">
        <Typography className="mb-8" variant="h2">
          {company?.displayName}
        </Typography>
        <AccountSummary
          campaignsCount={campaignsOverview?.activeCampaigns}
          newApplicantsCount={campaignsOverview?.newApplicants}
          contactedApplicants={campaignsOverview?.contactedApplicants}
        />
        <div className="mt-8 flex items-start">
          {campaigns.data.length === 0 ? (
            <div className="flex">
              {showOnfleetCard ? <OnfleetCard onClose={() => {}} /> : null}
              <CreateCampaignCard />
            </div>
          ) : null}
          {campaigns.data.length !== 0 ? (
            <CampaignsTableContainer
              campaigns={campaigns}
              limit={5}
              disableSearch
              onPaginationChange={handleCampaignsPaginationChange}
            />
          ) : null}
        </div>
      </div>
      <StartTrialModal
        open={showStartTrialModal}
        onClose={() => {
          setShowStartTrialModal(false)
        }}
        onCreateCampaign={() => {
          setShowStartTrialModal(false)

          navigate(ROUTES.FIRST_CREATE_CAMPAIGN_URL)
        }}
      />
    </SecurePage>
  )
}
