import { Typography } from '@mui/material'
import { SecurePage } from 'src/components/layout/SecurePage'
import { AccountCredentials } from 'src/components/account/AccountCredentials'
import { AccountDetailsForm } from 'src/components/account/account-details-form/AccountDetailsForm'

export const AccountPage = () => {
  return (
    <SecurePage>
      <div className="w-full px-8 py-12 items-start flex flex-col">
        <div>
          <Typography variant="h2">Account</Typography>
        </div>
        <AccountCredentials />
        <AccountDetailsForm />
      </div>
    </SecurePage>
  )
}
