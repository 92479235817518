import React, { useEffect } from 'react'
import { Box, Modal, Button, Typography } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'

interface Props {
  onCreateCampaign: () => void
  onClose: () => void
  open?: boolean
}

export const STORAGE_TRIAL_START_SHOWN_KEY = 'trialStartShown'

const StartTrialModalComponent = ({ onCreateCampaign, onClose, open }: Props) => {
  useEffect(() => {
    localStorage.setItem(STORAGE_TRIAL_START_SHOWN_KEY, 'yes')
  })
  return (
    <Modal
      open={!!open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '780px',
          bgcolor: 'background.paper',
          p: '50px 150px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h2">You’ve started your free trial!</Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
          ParaPartners is free for now! We will be in touch about next steps for expanding your
          recruitment reach with our paid partnership plans.{' '}
        </Typography>
        <Typography sx={{ marginTop: '24px' }} variant="h3">
          $0 / Free{' '}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}>
          <CheckCircle style={{ marginRight: 12 }} />
          <Typography variant="body1">Create and launch up to 5 campaigns</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircle style={{ marginRight: 12 }} />
          <Typography variant="body1">Collect up to 100 responses per campaign</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '48px' }}>
          <CheckCircle style={{ marginRight: 12 }} />
          <Typography variant="body1">
            Pair with one ParaPartner customer support representative
          </Typography>
        </div>
        <Box>
          <Button
            sx={{ mr: 2, width: '230px' }}
            color="secondary"
            variant="outlined"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            sx={{ width: '230px' }}
            color="primary"
            variant="contained"
            onClick={onCreateCampaign}
          >
            Create A Campaign
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export const StartTrialModal = React.memo(StartTrialModalComponent)
