import React from 'react'
import { Box, Button, Typography } from '@mui/material'

interface Props {
  onClose(): void
}

const EmptyTeamsModalContentComponent = ({ onClose }: Props) => {
  return (
    <>
      <Typography variant="h2">You don't have any available Onfleet teams for import</Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        Please go to Onfleet and create the team{' '}
      </Typography>

      <Box>
        <Button
          sx={{ mr: 2, width: '230px' }}
          color="secondary"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          sx={{ width: '230px' }}
          color="primary"
          variant="contained"
          target="_blank"
          href="https://onfleet.com/"
        >
          Go to Onfleet
        </Button>
      </Box>
    </>
  )
}

export const EmptyTeamsModalContent = React.memo(EmptyTeamsModalContentComponent)
