import { OnboardingContainer } from 'src/components/layout/OnboardingContainer'
import { PublicPage } from 'src/components/layout/PublicPage'
import { HaveOnfleetContent } from 'src/components/onboarding/have-onfleet-content/HaveOnfleetContent'

export const HaveOnfleetOnboardingPage = () => {
  return (
    <PublicPage>
      <OnboardingContainer
        title="Do you have an Onfleet account?"
        subtitle="We use your onfleet API Key to link applicant data directly from ParaPartners."
        screenNumber={2}
      >
        <HaveOnfleetContent />
      </OnboardingContainer>
    </PublicPage>
  )
}
