export enum CampaignStatus {
  PendingApproval = 'pendingApproval',
  Approved = 'approved',
  Active = 'active',
  Inactive = 'inactive',
}

export const CampaignStatusMap: { [key in CampaignStatus]: string } = {
  [CampaignStatus.PendingApproval]: 'Pending Review',
  [CampaignStatus.Approved]: 'Inactive',
  [CampaignStatus.Active]: 'Launched',
  [CampaignStatus.Inactive]: 'Inactive',
}
