import React from 'react'
import { Box, Button, Typography } from '@mui/material'

interface Props {
  onClose(): void
}

const ImportToOnfleetModalSuccessContentComponent = ({ onClose }: Props) => {
  return (
    <>
      <Typography variant="h2" sx={{ maxWidth: '450px' }}>
        Drivers imported successfully!
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4, maxWidth: '450px' }}>
        You will now see your driver’s details and be able to assign them tasks through onfleet.{' '}
      </Typography>{' '}
      <Box>
        <Button sx={{ width: '230px' }} color="primary" variant="contained" onClick={onClose}>
          Done
        </Button>
      </Box>
    </>
  )
}

export const ImportToOnfleetModalSuccessContent = React.memo(
  ImportToOnfleetModalSuccessContentComponent,
)
