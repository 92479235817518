import React from 'react'
import { Box, Modal, Button, Typography } from '@mui/material'

interface Props {
  onSignUp: () => void
  onCompleteLater: () => void
  open?: boolean
}

const CreateOnfleetAccountModalComponent = ({ onSignUp, onCompleteLater, open }: Props) => {
  return (
    <Modal
      open={!!open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '680px',
          bgcolor: 'background.paper',
          p: '50px 100px',
          borderRadius: '8px',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h2">Do you want to create an onfleet account?</Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
          Onfleet is where you will assign and interact with users once they have been onboarded to
          your platform.{' '}
        </Typography>
        <Box>
          <Button
            sx={{ mr: 2, width: '230px' }}
            color="secondary"
            variant="outlined"
            onClick={onCompleteLater}
          >
            Complete Later
          </Button>
          <Button sx={{ width: '230px' }} color="primary" variant="contained" onClick={onSignUp}>
            Sign up for Onfleet
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export const CreateOnfleetAccountModal = React.memo(CreateOnfleetAccountModalComponent)
