import { combineReducers } from 'redux'
import { authReducer } from './auth'
import { onboardingReducer } from './onboarding'
import { companyReducer } from './company'
import { campaignsReducer } from './campaigns'
import { campaignCreateReducer } from './campaign-create'
import { applicantsReducer } from './applicants'
import { onfleetReducer } from './onfleet'

export const rootReducer = combineReducers({
  auth: authReducer,
  onboarding: onboardingReducer,
  company: companyReducer,
  campaigns: campaignsReducer,
  applicants: applicantsReducer,
  campaignCreate: campaignCreateReducer,
  onfleet: onfleetReducer,
})
