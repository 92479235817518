import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Typography } from '@mui/material'

interface ApplicantsTableHeaderCell {
  id: string
  label: string
}

const headCells: readonly ApplicantsTableHeaderCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'phone',
    label: 'Phone',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'responseDate',
    label: 'Response Date',
  },
  {
    id: 'contactStatus',
    label: 'Contact Status',
  },
  {
    id: 'onfleetImported',
    label: 'Onfleet Status',
  },
]

export const ApplicantsTableHeader = () => {
  return (
    <TableHead style={{ backgroundColor: 'black' }}>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" padding="normal">
            <Typography color="white" fontWeight={600}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
