import Typography from '@mui/material/Typography'
import { ForgotPasswordConfirmForm } from 'src/components/auth/forgot-password-confirm-form/ForgotPasswordConfirmForm'
import { PublicPage } from 'src/components/layout/PublicPage'
import { AuthFormContainer } from 'src/components/layout/AuthFormContainer'

export const ForgotPasswordConfirmPage = () => {
  return (
    <PublicPage>
      <AuthFormContainer>
        <Typography variant="h4" fontWeight={600}>
          Forgot Password
        </Typography>
        <Typography className="mb-6" variant="h6">
          Create a new password for your Para account.
        </Typography>
        <ForgotPasswordConfirmForm />
      </AuthFormContainer>
    </PublicPage>
  )
}
