import styled from '@emotion/styled'

export const BrandHeader = () => {
  return (
    <Root>
      <InnerContainer className="flex justify-between">
        <LogoContainer>
          <Logo src={require('../../assets/images/para-logo.png')} />
        </LogoContainer>
      </InnerContainer>
    </Root>
  )
}

const Root = styled.div`
  padding: 16px 60px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
`

const InnerContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`

const LogoContainer = styled.div`
  font-size: 0;
`

const Logo = styled.img`
  width: 156px;
  height: 48px;
`
