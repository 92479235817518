import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { FetchCompany, CreateCompany, UpdateCompany } from 'src/constants/actionTypes'
import { ApiService, callSecureApi } from './api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { Company } from 'src/interfaces/company'

function* onFetchCompany(action: AsyncAction<void>) {
  try {
    const { payload }: { payload: Company } = yield call(callSecureApi, Endpoints.FetchCompany, {
      apiService: ApiService.Paraworks,
    })

    yield put(FetchCompany.success(payload))

    if (action.next) {
      action.next(null, payload as any)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(FetchCompany.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface CreateCompanyRequest {
  name: string
  driverFleet: string
  weeklyDeliveryVolume: string
  dispatchTools: string
  onfleetApiKey?: string
  logo?: string
}

function* onCreateCompany(action: AsyncAction<CreateCompanyRequest>) {
  try {
    const { name, driverFleet, weeklyDeliveryVolume, dispatchTools, onfleetApiKey, logo } =
      action.payload

    const { payload }: { payload: Company } = yield call(callSecureApi, Endpoints.CreateCompany, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      body: {
        name,
        driverFleet,
        weeklyDeliveryVolume,
        dispatchTools,
        onfleetApiKey,
        logo,
      },
    })

    yield put(CreateCompany.success(payload))
    yield put(FetchCompany.request())

    if (action.next) {
      action.next(null, payload as any)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(CreateCompany.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UpdateCompanyRequest {
  id: string
  name: string
  driverFleet: string
  weeklyDeliveryVolume: string
  dispatchTools: string
  onfleetApiKey?: string
  logo?: string
}

function* onUpdateCompany(action: AsyncAction<UpdateCompanyRequest>) {
  const { id, name, driverFleet, weeklyDeliveryVolume, dispatchTools, onfleetApiKey, logo } =
    action.payload

  try {
    const { payload }: { payload: Company } = yield call(callSecureApi, Endpoints.UpdateCompany, {
      apiService: ApiService.Paraworks,
      method: 'PUT',
      body: {
        id,
        name,
        driverFleet,
        weeklyDeliveryVolume,
        dispatchTools,
        onfleetApiKey,
        logo,
      },
      params: [
        {
          field: ':id',
          value: id,
        },
      ],
    })

    yield put(UpdateCompany.success(payload))
    yield put(FetchCompany.request())

    if (action.next) {
      action.next(null, payload as any)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch'

    yield put(UpdateCompany.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* companySaga() {
  yield takeEvery(FetchCompany.type.REQUEST, onFetchCompany)
  yield takeEvery(CreateCompany.type.REQUEST, onCreateCompany)
  yield takeEvery(UpdateCompany.type.REQUEST, onUpdateCompany)
}
