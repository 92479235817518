import { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompany } from 'src/selectors/company'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { FetchCompany } from 'src/constants/actionTypes'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'

interface Props {
  children: ReactNode
}

export const CompanyExist = ({ children }: Props) => {
  const [isReady, setIsReady] = useState(false)
  const company = useSelector(getCompany)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isReady) {
      return
    }

    if (company) {
      setIsReady(true)
    } else {
      const loadCompany = async () => {
        try {
          const company = await createAsyncAction(dispatch, FetchCompany.request())

          if (company) {
            setIsReady(true)
          } else {
            navigate(ROUTES.BUSINESS_SIZE_ONBOARDING_URL)
          }
        } catch (err) {
          navigate(ROUTES.BUSINESS_SIZE_ONBOARDING_URL)
        }
      }

      loadCompany()
    }
  }, [isReady, company])

  return isReady && company ? <>{children}</> : <></>
}
