import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import { colors } from 'src/theme'

interface Props {
  image: string
  title: string
  subtitle: string
}

export const AuthWelcomeContentListItem = ({ image, title, subtitle }: Props) => {
  return (
    <div className="flex mt-6">
      <ImageContainer className="mr-6">
        <Image src={image} />
      </ImageContainer>
      <div className="pt-1 w-80">
        <Typography variant="subtitle1" fontWeight={700} color={colors.BRAND_BLUE}>
          {title}
        </Typography>
        <Typography className="mt-1" variant="body1">
          {subtitle}
        </Typography>
      </div>
    </div>
  )
}

const ImageContainer = styled.div`
  font-size: 0;
`

const Image = styled.img`
  width: 48px;
  height: 48px;
`
